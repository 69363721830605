// saga.js

import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import axiosWithToken from '../../utils/api';

const formatUrl = (url, data) => {
  url = url + '?';
  if (data) {
    if (data.status) url += `&status=${data.status}`;
    if (data.type) url += `&type=${data.type}`;
    if (data.startDate) url += `&startDate=${data.startDate}`;
    if (data.endDate) url += `&endDate=${data.endDate}`;
    if (data.customer_id) url += `&customer_id=${data.customer_id}`;
    if (data.agent_id) url += `&agent_id=${data.agent_id}`;
    if (data.filter_sent_by_agent) url += `&filter_sent_by_agent=${data.filter_sent_by_agent}`;
    if (data.page) url += `&page=${data.page}`;
    if (data.page_size) url += `&page_size=${data.page_size}`;
  }
  if (url.includes("?&")) {
    return url.replace("&", "");
  }
  return url;
}

function* createDemandeRechargeSaga({ payload: { data } }) {
  try {
    const response = yield call(axiosWithToken.post, '/create/demande/recharge', data);
    yield put(actions.createDemandeRechargeSuccess(response.data));

  } catch (error) {
    
    yield put(actions.createDemandeRechargeFailure(error));
  }
}

function* createDemandeRetraitSaga({ payload: { data } }) {
  try {
    const response = yield call(axiosWithToken.post, '/create/demande/retrait', data);
    yield put(actions.createDemandeRetraitSuccess(response.data));
  } catch (error) {
    yield put(actions.createDemandeRetraitFailure(error));
  }
}

function* fetchDemandeDetailSaga({ payload: { demandeId } }) {
  try {
    const response = yield call(axiosWithToken.get, `/demandes/${demandeId}`);
    yield put(actions.fetchDemandeDetailSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchDemandeDetailFailure(error));
  }
}

function* fetchDemandesFilterListsSaga(data) {
  try {
    const response = yield call(axiosWithToken.get, formatUrl('/demandes-filter-lists', data));
    yield put(actions.fetchDemandesFilterListsSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchDemandesFilterListsFailure(error));
  }
}

function* fetchCustomerHistoryDemandesSaga({ payload: { data } }) {
  try {
    const response = yield call(axiosWithToken.get, formatUrl(`/customer/${data.userId}/history/demandes`, data));
    yield put(actions.fetchCustomerHistoryDemandesSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchCustomerHistoryDemandesFailure(error));
  }
}

function* fetchCustomerListDemandeByTypeSaga({ payload: { userId } }) {
  try {
    const response = yield call(axiosWithToken.get, `/customer/demandes/type/${userId}`);
    yield put(actions.fetchCustomerListDemandeByTypeSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchCustomerListDemandeByTypeFailure(error));
  }
}

function* updateDemandeSaga({ payload: { demandeId, data } }) {
  try {
    const response = yield call(axiosWithToken.put, `/update/demande/${demandeId}`, data);
    yield put(actions.updateDemandeSuccess(response.data));
  } catch (error) {
    yield put(actions.updateDemandeFailure(error));
  }
}

function* deleteDemandeSaga({ payload: { demandeId } }) {
  try {
    const response = yield call(axiosWithToken.delete, `/delete/demande/${demandeId}`);
    yield put(actions.deleteDemandeSuccess(response.data));
  } catch (error) {
    yield put(actions.deleteDemandeFailure(error));
  }
}

function* cancelDemandeSaga({ payload: { demandeId } }) {
  try {
    const response = yield call(axiosWithToken.post, `/cancel/demande/${demandeId}`);
    yield put(actions.cancelDemandeSuccess(response.data));
  } catch (error) {
    yield put(actions.cancelDemandeFailure(error));
  }
}

function* fetchAgentHistoryTraityDemandeSaga({ payload: { agentId } }) {
  try {
    const response = yield call(axiosWithToken.get, `/agents/${agentId}/history_traity_demande`);
    yield put(actions.fetchAgentHistoryTraityDemandeSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchAgentHistoryTraityDemandeFailure(error));
  }
}

function* fetchMyAccountSaga({ payload: { userId } }) {
  try {
    const response = yield call(axiosWithToken.get, `/my-account/${userId}`);
    yield put(actions.fetchMyAccountSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchMyAccountFailure(error));
  }
}

// Add other sagas as needed

function* watchDemandeSagas() {
  yield takeEvery(actionTypes.CREATE_DEMANDE_RECHARGE_REQUEST, createDemandeRechargeSaga);
  yield takeEvery(actionTypes.CREATE_DEMANDE_RETRAIT_REQUEST, createDemandeRetraitSaga);
  yield takeEvery(actionTypes.FETCH_DEMANDE_DETAIL_REQUEST, fetchDemandeDetailSaga);
  yield takeEvery(actionTypes.FETCH_DEMANDES_FILTER_LISTS_REQUEST, fetchDemandesFilterListsSaga);
  yield takeEvery(actionTypes.FETCH_CUSTOMER_HISTORY_DEMANDES_REQUEST, fetchCustomerHistoryDemandesSaga);
  yield takeEvery(actionTypes.FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_REQUEST, fetchCustomerListDemandeByTypeSaga);
  yield takeEvery(actionTypes.UPDATE_DEMANDE_REQUEST, updateDemandeSaga);
  yield takeEvery(actionTypes.DELETE_DEMANDE_REQUEST, deleteDemandeSaga);
  yield takeEvery(actionTypes.CANCEL_DEMANDE_REQUEST, cancelDemandeSaga);
  yield takeEvery(actionTypes.FETCH_AGENT_HISTORY_TRAITY_DEMANDE_REQUEST, fetchAgentHistoryTraityDemandeSaga);
  yield takeEvery(actionTypes.FETCH_MY_ACCOUNT_REQUEST, fetchMyAccountSaga);
}

export default function* demandeSaga() {
  yield watchDemandeSagas();
}
