import React, { useEffect, useState } from 'react';
import { useAuth } from "../contexts/auth-context";
import { useDispatch, useSelector } from 'react-redux';
import { types, currentUser, addUserNumber, affiliationAccount, withdrawalRequest, userUpdate } from '../store/actions';
import { Input, Button, Spinner } from 'reactstrap'
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import { BASE_URL } from "../utils/api";
const token = localStorage.getItem("access_token");

const Account = () => {
  const dispatch = useDispatch(); 

  const typesData = useSelector((state) => state.auth.typesData || []);
  const _currentUser = useSelector((state) => state.auth.userData || {});
  const userAffiliationAccount = useSelector((state) => state.auth.affiliation || {});
  const [loadingShow, setloadingShow] = useState(false);

  const error = useSelector((state) => state.auth.error || null);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [successDelete, setSuccessDelete] = useState(false);

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    window.location.href = "/";
  };

  useEffect(() => {
    dispatch(types());
    dispatch(currentUser());
    dispatch(affiliationAccount());
  }, [dispatch]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
      number: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Veuillez sélectionner le type de compte"),
      number: Yup.string().required("Veuillez entrer le numéro du compte"),
    }),
    onSubmit: (values) => {
      setloadingShow(true);
      const formData = { ...values, userId: _currentUser.id };
      dispatch(addUserNumber(formData));
      console.log(error)
      if (error?.message) {
        setErrorMessage(error?.message);
        setSuccessMessage("");
        setloadingShow(false);
        return;
      }
      setSuccessMessage("Compte ajouté avec succès");
      setErrorMessage("");
      dispatch(currentUser());
      setloadingShow(false)
    },
  });

  const validationWithdrawal = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: userAffiliationAccount.current_balance,
    },
    validationSchema: Yup.object({
      amount: Yup.string().required("Veuillez entrer le montant"),
    }),
    onSubmit: (values) => {
      setloadingShow(true);
      const formWithdrawalData = { ...values, userId: _currentUser.id };
      dispatch(withdrawalRequest(formWithdrawalData));
      if (error?.message) {
        setErrorMessage(error?.message);
        setSuccessMessage("");
        setloadingShow(false);
        return;
      }
      setSuccessMessage("Demande envoyée avec succès");
      setErrorMessage("");
      dispatch(affiliationAccount());
      setloadingShow(false)
    },
  });

  const validationUpdate = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: _currentUser.name,
      phone: _currentUser.phone,
      email: _currentUser.email
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez entrer le nom"),
      phone: Yup.string().required("Veuillez entrer le téléphone"),
      email: Yup.string().required("Veuillez entrer l'adresse email"),
    }),
    onSubmit: (values) => {
      setloadingShow(true);
      const formUpdateUserData = { ...values, userId: _currentUser.id };
      dispatch(userUpdate(formUpdateUserData));
      if (error?.message) {
        setErrorMessage(error?.message);
        setSuccessMessage("");
        setloadingShow(false);
        return;
      }
      setSuccessMessage("Modification avec succès");
      setErrorMessage("");
      dispatch(affiliationAccount());
      setloadingShow(false)
    },
  });

  const date = new Date();
  const day = date.getDate();

  const copyCode = () => {
    navigator.clipboard.writeText(_currentUser.affiliation_compte?.affiliation_code);
  }

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/signup?parent=${_currentUser?.affiliation_compte?.affiliation_code}`
    );
  }

  /*const requestAccountNumberDel = (id) => {
    setloadingShow(true);
    setShowAlert(false);
    axios.delete(BASE_URL+"delete-user-number/" + id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          setSuccessDelete(true);
          // reload page after 2 secondes
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(function (error) {
          console.log(error.response.data);
        })
        .finally(() => {
          setloadingShow(false);
        });
  }*/

  return (
    <div className="container mt-6">
      <div className="col-12">
        {errorMessage !== "" && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        {successMessage !== "" && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
      </div>
      <div className="col-sm-6">
        <p style={{ fontSize: 26 }}>Informations du compte</p>
      </div>
      <div
        className="d-flex align-items-start"
        style={{ background: "#0b031c" }}
      >
        <div
          className="nav flex-column nav-pills"
          style={{ width: "30%", background: "#0b031c" }}
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            className="nav-link active"
            id="v-pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-compte"
            type="button"
            role="tab"
            aria-controls="v-pills-compte"
            aria-selected="true"
          >
            Mes comptes
          </button>
          <button
            className="nav-link"
            id="v-pills-affiliate-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-affiliate"
            type="button"
            role="tab"
            aria-controls="v-pills-affiliate"
            aria-selected="false"
          >
            Affiliation
          </button>
        </div>
        <div
          className="tab-content"
          id="v-pills-tabContent"
          style={{ width: "100%" }}
        >
          <div
            className="tab-pane fade show active"
            id="v-pills-compte"
            role="tabpanel"
            aria-labelledby="v-pills-compte-tab"
            tabIndex="0"
          >
            <div className="row  mt-100">
              <div className="col-sm-100">
                <div className="card">
                  <div className="card-header text-center">
                    <span className="me-3">Les numéros de compte</span>{" "}
                    <button
                      className="btn btn-sm btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#addNumberCompte"
                    >
                      Ajouter un compte
                    </button>
                  </div>
                  <div className="card-body row">
                    <form className="row">
                      <div className="col-auto">
                        <input
                          required
                          onChange={validationUpdate.handleChange}
                          onBlur={validationUpdate.handleBlur}
                          value={validationUpdate.values.name}
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Nom et prénoms"
                        />
                      </div>
                      <div className="col-auto">
                        <input
                          required
                          onChange={validationUpdate.handleChange}
                          onBlur={validationUpdate.handleBlur}
                          value={validationUpdate.values.phone}
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder="Téléphone"
                        />
                      </div>
                      <div className="col-auto">
                        <input
                          required
                          onChange={validationUpdate.handleChange}
                          onBlur={validationUpdate.handleBlur}
                          value={validationUpdate.values.email}
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-auto">
                        <Button
                          color="primary"
                          disabled={loadingShow ? true : false}
                          className="btn btn-primary mb-3"
                          type="submit"
                          onClick={validationUpdate.handleSubmit}
                        >
                          {loadingShow ? (
                            <Spinner size="sm" className="me-2">
                              Changement...
                            </Spinner>
                          ) : null}
                          Modifier
                        </Button>
                      </div>
                      <hr></hr>
                    </form>
                    {_currentUser?.user_number?.map((userNumber, index) => (
                      <div className="col-sm-4 mb-2" key={index}>
                        <div className="card">
                          <div className="card-body">
                            <p className="card-text">
                              N°: <strong>{userNumber.numero}</strong>
                            </p>
                            <small className="blockquote-footer">
                              Type : {userNumber.type.name}
                            </small>
                          </div>
                        </div>
                        {/*<button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            setShowAlert(true);
                            setSelectedAccount(userNumber.id);
                          }}
                        >
                          <i className="fas fa-trash">Suppimer le compte</i>
                        </button>*/}
                        {/* showAlert && selectedAccount === userNumber.id && (
                          <div className="alert alert-danger">
                            <p style={{ padding: 5 }}>
                              Voulez-vous vraiment supprimer ce compte?{" "}
                              <button
                                className="btn btn-sm btn-danger"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                  requestAccountNumberDel(userNumber.id);
                                }}
                              >
                                Oui
                              </button>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  setShowAlert(false);
                                }}
                              >
                                Non
                              </button>
                            </p>
                          </div>
                        )}
                        {successDelete && selectedAccount === userNumber.id && (
                          <div className="alert alert-success">
                            Compte supprimé avec succès
                          </div>
                        )*/}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-affiliate"
            role="tabpanel"
            aria-labelledby="v-pills-affiliate-tab"
            tabIndex="0"
          >
            <div className="row p-4">
              <div className="col-sm-8">
                <div className="row">
                  {
                    // To remove after updating the affiliation system
                    <span className="alert alert-warning">
                      Les retraits des gains seront désormais possible les dates
                      7 du mois
                    </span>
                  }
                  <div className="col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body">
                        <p className="card-text">Balance courante</p>
                        <span className="btn btn-primary me-2">
                          {userAffiliationAccount.current_balance} XOF
                        </span>
                        <span>
                          <button
                            disabled={
                              (userAffiliationAccount.current_balance <
                                userAffiliationAccount.min_withdraval_data) ||
                              day !== 7
                            }
                            data-bs-toggle="modal"
                            data-bs-target="#makeWithdrawal"
                            className="btn btn-outline-success"
                          >
                            Retirer
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body">
                        <p className="card-text">Montant total obtenu</p>
                        <span className="btn btn-warning">
                          {userAffiliationAccount.total_recipient} XOF
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body">
                        <p className="card-text">Montant total récupéré</p>
                        <span className="btn btn-danger">
                          {userAffiliationAccount.total_get} XOF
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-body">
                        <p className="card-text">Nombre d'affiliés</p>
                        <span className="btn btn-info">
                          {userAffiliationAccount.number_children}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="mb-3">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Votre code d'affiliation:
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="formGroupExampleInput"
                    disabled
                    value={_currentUser?.affiliation_compte?.affiliation_code}
                  />
                  <button
                    className="btn btn-sm btn-primary mt-3"
                    onClick={copyCode}
                  >
                    Copier le code
                  </button>
                  <Input
                    style={{ marginTop: 20 }}
                    type="text"
                    className="form-control"
                    id="formGroupUrlInput"
                    disabled
                    value={`${window.location.origin}/signup?parent=${_currentUser?.affiliation_compte?.affiliation_code}`}
                  />
                  <button
                    className="btn btn-sm btn-primary mt-3"
                    onClick={copyLink}
                  >
                    Copier le lien
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addNumberCompte"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ajouter un compte
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Type
                  </label>
                  <select
                    className="form-select mb-3"
                    aria-label="Selectionner le type de compte"
                    id="steparrow-gen-info-type-input"
                    required
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.type}
                    name="type"
                  >
                    <option value="">Choisir le type de compte</option>
                    {typesData?.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="numberCompte" className="form-label">
                    Numéro de compte
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="numberCompte"
                    placeholder="Numéro de compte"
                    required
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.number}
                    name="number"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <Button
                color="primary"
                disabled={loadingShow ? true : false}
                className="btn btn-primary"
                type="submit"
                onClick={validation.handleSubmit}
              >
                {loadingShow ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Changement...{" "}
                  </Spinner>
                ) : null}
                Soumettre la demande
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="makeWithdrawal"
        aria-labelledby="makeWithdrawalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="makeWithdrawalTitle">
                Demande de retrait
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="amount" className="form-label">
                    Montant
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    placeholder="Montant"
                    min={userAffiliationAccount.min_withdraval_data}
                    required
                    onChange={validationWithdrawal.handleChange}
                    onBlur={validationWithdrawal.handleBlur}
                    value={validationWithdrawal.values.amount}
                    name="amount"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <Button
                color="primary"
                disabled={loadingShow ? true : false}
                className="btn btn-primary"
                type="submit"
                onClick={validationWithdrawal.handleSubmit}
              >
                {loadingShow ? (
                  <Spinner size="sm" className="me-2">
                    Changement...
                  </Spinner>
                ) : null}
                Soumettre la demande
              </Button>
            </div>
          </div>
        </div>
      </div>
      <p style={{ marginTop: 50 }}>
        <button className="btn btn-danger btn-sm" onClick={handleLogout}>
          Se déconnecter
        </button>
      </p>
    </div>
  );
}

export default Account;