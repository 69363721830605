import React from 'react';
import classes from '../styles/footer.module.css';
import fb from '../images/payxof-assets/facebook.png';
import insta from '../images/payxof-assets/insta-img.png';
import tiktok from '../images/payxof-assets/tiktok.png';
import google_play from '../images/payxof-assets/google-play-logo.png';
import { MailIcon, PhoneCallIcon, LocalisationIcon } from '../images/payxof-assets/svgs';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className={classes["main-block"]}>
        <div className={classes["main-section"]}>
          <div className={classes["first-section"]}>
            <p className={classes["custom-text"]}>Découvrez notre politique de confidentialité </p>
            <a className={classes["call-in-action-btn"]} href={""}>  Parcourir</a>
          </div>
          <div className={classes["second-section"]}>
            <div className={classes["contact-block"]}>
              <p className={classes["contact-block__title"]}>Nous Contacter</p>
              <div className={classes["contact-block-second-section"]}>
                <div className={classes["icon-style-block"]}>
                  <LocalisationIcon />
                  <p className={`${classes["icon-desc"]} ${classes["icon-desc-location"]}`}>Cotonou, Bénin</p>
                </div>
                <div className={classes["icon-style-block"]}>
                  <MailIcon />
                  <p className={classes["icon-desc"]}>payxofs@gmail.com</p>
                </div>
              </div>
            </div>
            <div className={classes["follow-block"]}>
              <p className={classes["contact-block__title"]}>Nous suivre</p>
              <div className={classes["social-media-block"]} >
                <Link to="https://www.facebook.com/web.payxof?mibextid=LQQJ4d" >
                  <img src={fb} alt="" className={classes["social-media-icon"]} />
                </Link>
                <Link to="https://www.tiktok.com/@payxof.com?_t=8jvfRLwXiCs&_r=1" >
                  <img src={tiktok} alt="" className={classes["social-media-icon"]} />
                </Link>
                <Link to="https://www.instagram.com/web.payxof?igsh=anNqZDc2OTcwcDA3" >
                  <img src={insta} alt="" className={classes["social-media-icon"]} />
                </Link>


              </div>
            </div>
            <div className={classes["download-block"]}>
              <p className={classes["contact-block__title"]}>Télécharger</p>
              <Link to="https://drive.google.com/file/d/1io-NHb1bU_VZNTXGv5mmDrA1dtr9UWfR/view?usp=sharing" >
                <img src={google_play} alt="" className={classes["google-play-icon"]} />
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <p className={classes["rights-text-styles"]}>&#169; {new Date().getFullYear()} payxof tous droits réservés</p>
      </footer>
    </React.Fragment>
  );
};

export default Footer;