import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerHistoryDemandes } from '../store/actions';
import { useAuth } from "../contexts/auth-context";
import { Col, Row, Button } from 'reactstrap'
import moment from 'moment';

const History = () => {
    const dispatch = useDispatch();
    const [loadingShow, setloadingShow] = useState(false);
    const [error, setError] = useState(null);
    const { user, userRole } = useAuth();
    const [paginateData, setPaginateData] = useState({
        demandes: [],
        pageOptions: 1,
        previousPage: 0,
        nextPage: 0,
        pageIndex: 0,
        canNextPage: false,
        canPreviousPage: false,
        page: 1,
        page_size: 6,
      })
      
      const customerHistoryDemandes = useSelector((state) => state.demande.customerHistoryDemandes);
      //console.log(customerHistoryDemandes)

      
      
      useEffect(() => {
        if (customerHistoryDemandes) {
          setPaginateData({
            demandes: customerHistoryDemandes.data,
            pageOptions: customerHistoryDemandes.total_pages,
            previousPage: customerHistoryDemandes.previousPage,
            nextPage: customerHistoryDemandes.nextPage,
            pageIndex: customerHistoryDemandes.current_page,
            canNextPage: customerHistoryDemandes.canNextPage,
            canPreviousPage: customerHistoryDemandes.canPreviousPage,
          });
        }
      }, [customerHistoryDemandes]);
      
      
        useEffect(() =>{
          const fetchData = async () => {
            try {
              if(user && userRole === 'Customer') {
                setloadingShow(true);
                await dispatch(fetchCustomerHistoryDemandes(
                  {
                    userId: user,
                    page: paginateData.page ? paginateData.page : 1,
                    page_size: paginateData.page_size ? paginateData.page_size : 6,
                  }));
                  setloadingShow(false);
              }
            } catch (error) {
              console.error("Une erreur s'est produite lors de la récupération de l'historique des demandes :", error);
              setError(
                "Une erreur s'est produite lors de la récupération de l'historique des demandes"
              );
            }
          };
          fetchData();
        }, [dispatch, user, userRole, paginateData.page, paginateData.page_size]);

        const nextOrPreviousPage = async (value) => {
          setloadingShow(true);
            if (value === 'next') {
              dispatch(fetchCustomerHistoryDemandes(
                {
                  userId: user,
                  page: paginateData.nextPage,
                  page_size: paginateData.page_size ? paginateData.page_size : 6,
                }
              ));
            } else {
              dispatch(fetchCustomerHistoryDemandes(
                {
                  userId: user,
                  page: paginateData.previousPage,
                  page_size: paginateData.page_size ? paginateData.page_size : 6,
                }
              ));
              
            }
            setloadingShow(false);
          }

    return (
      <div className="container mt-4">
        <h2>Historique de mes demandes</h2>
        <Row className="justify-content-md-end justify-content-center align-items-center p-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => nextOrPreviousPage("previous")}
                disabled={!paginateData.canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {paginateData.pageIndex} sur {paginateData.pageOptions}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => nextOrPreviousPage("next")}
                disabled={!paginateData.canNextPage}
              >
                {">"}
              </Button>
            </div>
          </Col>
        </Row>
        <div className="row">
          {error && (
            <div className="col-sm-12">
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title text-danger">{error}</h5>
                </div>
              </div>
            </div>
          )}
          {loadingShow && (
            <div className="col-sm-12">
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Chargement...</h5>
                </div>
              </div>
            </div>
          )}
          {paginateData.demandes?.map((demande) => (
            <div key={demande.id} className="col-sm-4">
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Demande #{demande.id}</h5>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between">
                      <span className="font-weight-bold">Montant:</span>
                      <span>{demande.amount}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span className="font-weight-bold">Status:</span>
                      <span
                        style={
                          demande.status === "PROGRESS" ||
                          demande.status === "SENT"
                            ? { color: "orange" }
                            : demande.status === "FAILED"
                            ? { color: "red" }
                            : { color: "green" }
                        }
                      >
                        {demande.status === "FAILED"
                          ? "Rejeté"
                          : demande.status === "SENT"
                          ? "En attente"
                          : demande.status === "PROGRESS"
                          ? "En cours"
                          : "Traité"}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span className="font-weight-bold">Date de demande:</span>
                      <span>
                        {moment(demande.date_creation).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span className="font-weight-bold">Type de compte:</span>
                      <span>{demande.type_compte.name}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span className="font-weight-bold">
                        Numéro de compte:
                      </span>
                      <span>{demande.compte.numero}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span className="font-weight-bold">Type de demande:</span>
                      <span>{demande.demande_type}</span>
                    </li>
                    {demande.status === "FAILED" && (
                      <li className="list-group-item d-flex justify-content-between">
                        <span className="font-weight-bold">Raison:</span>
                        <span>{demande.notes}</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          ))}
          {paginateData.demandes?.length === 0 && (
            <div className="col-sm-12">
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">Aucune demande trouvée</h5>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default History;
