import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const localStorageKeyPrefix = 'payxof_';

  const getTokenFromLocalStorage = () => localStorage.getItem('access_token');
  const getUserFromLocalStorage = () => localStorage.getItem(`${localStorageKeyPrefix}user`);
  const getUserRoleFromLocalStorage = () => localStorage.getItem(`${localStorageKeyPrefix}userRole`);

  const [token, setToken] = useState(getTokenFromLocalStorage() || null);
  const [user, setUserId] = useState(getUserFromLocalStorage() || '');
  const [userRole, setUserRole] = useState(getUserRoleFromLocalStorage() || '');

  useEffect(() => {
    const storedToken = getTokenFromLocalStorage();
    const storedUser = getUserFromLocalStorage();
    const storedUserRole = getUserRoleFromLocalStorage();

    if (storedToken) setToken(storedToken);
    if (storedUser) setUserId(storedUser);
    if (storedUserRole) setUserRole(storedUserRole);
  }, []);

  const authenticate = (id) => {
    const token = localStorage.getItem('access_token');
    localStorage.setItem(`${localStorageKeyPrefix}user`, id);
    setToken(token);
    setUserId(id);

    localStorage.setItem(`${localStorageKeyPrefix}user`, id);
    setUserId(id);
  };

  const assignRole = (role) => {
    localStorage.setItem(`${localStorageKeyPrefix}userRole`, role);
    setUserRole(role);
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    setToken('');

    localStorage.removeItem(`${localStorageKeyPrefix}userRole`);
    setUserRole('');

    localStorage.removeItem(`${localStorageKeyPrefix}user`);
    setUserId('');
  };

  const isAuthenticated = !!token;

  const contextValue = {
    token,
    user,
    userRole,
    isAuthenticated,
    authenticate,
    assignRole,
    logout,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
