// reducers.js

import * as actionTypes from './actionTypes';

const initialState = {
  demandes: [],
  error: null,
  rechargeSuccess: false,
  retraitSuccess: false,
  updateSuccess: false,
  deleteSuccess: false,
  cancelSuccess: false,
  beginTreatmentSuccess: false,
  demandeDetail: null,
  customerHistoryDemandes: [],
  customerListDemandeByType: [],
  agentHistoryTraityDemande: [],
  myAccountData: null,
  // Add other state properties as needed
};

const demandeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_DEMANDE_RECHARGE_SUCCESS:
      return {
        ...state, rechargeSuccess: true
      };

    case actionTypes.CREATE_DEMANDE_RECHARGE_FAILURE:
      return {
        ...state, rechargeSuccess: false, error: action.payload
      };

    case actionTypes.CREATE_DEMANDE_RETRAIT_SUCCESS:
      return {
        ...state, retraitSuccess: true
      };

    case actionTypes.CREATE_DEMANDE_RETRAIT_FAILURE:
      return {
        ...state, retraitSuccess: false, error: action.payload
      };

    case actionTypes.FETCH_DEMANDE_DETAIL_SUCCESS:
      return {
        ...state, demandeDetail: action.payload
      };

    case actionTypes.FETCH_DEMANDES_FILTER_LISTS_SUCCESS:
      return {
        ...state, demandes: action.payload
      };

    case actionTypes.FETCH_CUSTOMER_HISTORY_DEMANDES_SUCCESS:
      return {
        ...state, customerHistoryDemandes: action.payload
      };

    case actionTypes.FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_SUCCESS:
      return {
        ...state, customerListDemandeByType: action.payload
      };

    case actionTypes.UPDATE_DEMANDE_SUCCESS:
      return {
        ...state, updateSuccess: true
      };

    case actionTypes.UPDATE_DEMANDE_FAILURE:
      return {
        ...state, updateSuccess: false, error: action.payload
      };

    case actionTypes.DELETE_DEMANDE_SUCCESS:
      return {
        ...state, deleteSuccess: true
      };

    case actionTypes.DELETE_DEMANDE_FAILURE:
      return {
        ...state, deleteSuccess: false, error: action.payload
      };

    case actionTypes.CANCEL_DEMANDE_SUCCESS:
      return {
        ...state, cancelSuccess: true
      };

    case actionTypes.CANCEL_DEMANDE_FAILURE:
      return {
        ...state, cancelSuccess: false, error: action.payload
      };

    case actionTypes.BEGIN_TREATMENT_DEMANDE_SUCCESS:
      return {
        ...state, beginTreatmentSuccess: true
      };

    case actionTypes.BEGIN_TREATMENT_DEMANDE_FAILURE:
      return {
        ...state, beginTreatmentSuccess: false, error: action.payload
      };

    case actionTypes.FETCH_AGENT_HISTORY_TRAITY_DEMANDE_SUCCESS:
      return {
        ...state, agentHistoryTraityDemande: action.payload
      };

    case actionTypes.FETCH_MY_ACCOUNT_SUCCESS:
      return {
        ...state, myAccountData: action.payload
      };

    default:
      return state;
  }
};

export default demandeReducer;
