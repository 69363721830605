import React, { useState } from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import menuClasses from "../styles/menu_layoutOut.module.css";
import logo from "../images/payxof-assets/logo.png";
import Footer from "./Footer";

const LayoutOut = () => {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  // Define menu items with their corresponding paths
  const menuItems = [
    { path: "/", label: "Accueil" },
    { path: "/signin", label: "Se connecter" },
    { path: "/signup", label: "S'inscrire" },
    { path: "/help", label: "Aide" },
  ];

  return (
    <>
      <nav className={menuClasses["main-nav"]}>
        <div>
          <Link to="/">
            <img
              className={`img-fluid p-0 me-0 ${menuClasses.imgLogo}`}
              src={logo} alt="payxof"
            />
          </Link>
        </div>

        <ul className={menuClasses["main-menu"]}>
          {menuItems.map((item) => {
            const isActive = location.pathname === item.path;
            return (
              <li className="" key={item.path}>
                <Link
                  className={`${isActive ? menuClasses["active-menu-link"] : ""} ${menuClasses["menu-link"]}`}
                  to={item.path}
                >
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="burger-menu" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </nav>
      <Outlet />
      <Footer />
      <div className={`responsive-menu ${isMenuOpen ? 'responsive-menu-on-appear' : ''}`}>
        <ul className="">
          {menuItems.map((item) => {
            const isActive = location.pathname === item.path;
            return (
              <li className="" key={item.path}>
                <Link
                  className={`${isActive ? menuClasses["active-menu-link"] : ""} ${menuClasses["menu-link"]}`}
                  to={item.path}
                >
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
        <button className='close-responsive-menu' onClick={toggleMenu}>
          Fermer
        </button>
      </div>
    </>
  );
};

export default LayoutOut;
