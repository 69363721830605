// actions.js
import * as actionTypes from './actionTypes';

export const createDemandeRecharge = (data) => ({
  type: actionTypes.CREATE_DEMANDE_RECHARGE_REQUEST,
  payload: { data },
});

export const createDemandeRechargeSuccess = (data) => ({
  type: actionTypes.CREATE_DEMANDE_RECHARGE_SUCCESS,
  payload: data,
});

export const createDemandeRechargeFailure = (error) => ({
  type: actionTypes.CREATE_DEMANDE_RECHARGE_FAILURE,
  payload: error,
});

export const createDemandeRetrait = (data) => ({
  type: actionTypes.CREATE_DEMANDE_RETRAIT_REQUEST,
  payload: {data},
});

export const createDemandeRetraitSuccess = (data) => ({
  type: actionTypes.CREATE_DEMANDE_RETRAIT_SUCCESS,
  payload: data,
});

export const createDemandeRetraitFailure = (error) => ({
  type: actionTypes.CREATE_DEMANDE_RETRAIT_FAILURE,
  payload: error,
});

export const fetchDemandeDetail = (demandeId) => ({
  type: actionTypes.FETCH_DEMANDE_DETAIL_REQUEST,
  payload: demandeId,
});

export const fetchDemandeDetailSuccess = (data) => ({
  type: actionTypes.FETCH_DEMANDE_DETAIL_SUCCESS,
  payload: data,
});

export const fetchDemandeDetailFailure = (error) => ({
  type: actionTypes.FETCH_DEMANDE_DETAIL_FAILURE,
  payload: error,
});

export const fetchDemandesFilterLists = (data) => ({
  type: actionTypes.FETCH_DEMANDES_FILTER_LISTS_REQUEST,
  payload: {data},
});

export const fetchDemandesFilterListsSuccess = (data) => ({
  type: actionTypes.FETCH_DEMANDES_FILTER_LISTS_SUCCESS,
  payload: data,
});

export const fetchDemandesFilterListsFailure = (error) => ({
  type: actionTypes.FETCH_DEMANDES_FILTER_LISTS_FAILURE,
  payload: error,
});

export const fetchCustomerHistoryDemandes = (data) => ({
  type: actionTypes.FETCH_CUSTOMER_HISTORY_DEMANDES_REQUEST,
  payload: {data},
});

export const fetchCustomerHistoryDemandesSuccess = (data) => ({
  type: actionTypes.FETCH_CUSTOMER_HISTORY_DEMANDES_SUCCESS,
  payload: data,
});

export const fetchCustomerHistoryDemandesFailure = (error) => ({
  type: actionTypes.FETCH_CUSTOMER_HISTORY_DEMANDES_FAILURE,
  payload: error,
});

export const fetchCustomerListDemandeByType = (userId) => ({
  type: actionTypes.FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_REQUEST,
  payload: { userId },
});

export const fetchCustomerListDemandeByTypeSuccess = (data) => ({
  type: actionTypes.FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_SUCCESS,
  payload: data,
});

export const fetchCustomerListDemandeByTypeFailure = (error) => ({
  type: actionTypes.FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_FAILURE,
  payload: error,
});

export const updateDemande = (demandeId, data) => ({
  type: actionTypes.UPDATE_DEMANDE_REQUEST,
  payload: { demandeId, data },
});

export const updateDemandeSuccess = (data) => ({
  type: actionTypes.UPDATE_DEMANDE_SUCCESS,
  payload: data,
});

export const updateDemandeFailure = (error) => ({
  type: actionTypes.UPDATE_DEMANDE_FAILURE,
  payload: error,
});

export const deleteDemande = (demandeId) => ({
  type: actionTypes.DELETE_DEMANDE_REQUEST,
  payload: { demandeId },
});

export const deleteDemandeSuccess = (data) => ({
  type: actionTypes.DELETE_DEMANDE_SUCCESS,
  payload: data,
});

export const deleteDemandeFailure = (error) => ({
  type: actionTypes.DELETE_DEMANDE_FAILURE,
  payload: error,
});

export const cancelDemande = (demandeId) => ({
  type: actionTypes.CANCEL_DEMANDE_REQUEST,
  payload: { demandeId },
});

export const cancelDemandeSuccess = (data) => ({
  type: actionTypes.CANCEL_DEMANDE_SUCCESS,
  payload: data,
});

export const cancelDemandeFailure = (error) => ({
  type: actionTypes.CANCEL_DEMANDE_FAILURE,
  payload: error,
});

export const fetchAgentHistoryTraityDemande = (agentId) => ({
  type: actionTypes.FETCH_AGENT_HISTORY_TRAITY_DEMANDE_REQUEST,
  payload: { agentId },
});

export const fetchAgentHistoryTraityDemandeSuccess = (data) => ({
  type: actionTypes.FETCH_AGENT_HISTORY_TRAITY_DEMANDE_SUCCESS,
  payload: data,
});

export const fetchAgentHistoryTraityDemandeFailure = (error) => ({
  type: actionTypes.FETCH_AGENT_HISTORY_TRAITY_DEMANDE_FAILURE,
  payload: error,
});

export const fetchMyAccount = (userId) => ({
  type: actionTypes.FETCH_MY_ACCOUNT_REQUEST,
  payload: { userId },
});

export const fetchMyAccountSuccess = (data) => ({
  type: actionTypes.FETCH_MY_ACCOUNT_SUCCESS,
  payload: data,
});

export const fetchMyAccountFailure = (error) => ({
  type: actionTypes.FETCH_MY_ACCOUNT_FAILURE,
  payload: error,
});
