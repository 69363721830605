import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as api from '../../utils/AuthApi'; 

function* loginUser({ payload: { data } }) {
  try {
    const userData = yield call(api.login, data);
    if (userData) {
      yield put(actions.loginSuccess(userData.data));
      localStorage.setItem('access_token', userData.data.access);
    }
  } catch (error) {
    yield put(actions.loginFailure(error));
  }
}

function* logout(){
  try {
    const logoutData = yield call(api.logout);
    if (logoutData) {
      yield put(actions.logoutSuccess(logoutData.data));
      localStorage.removeItem('access_token');
    }
  } catch (error) {
    yield put(actions.logoutFailure(error));
  }
}

function* customerRegister({ payload: { data, history } }) {
  try {
    const userData = yield call(api.customerRegister, data);
    if (userData) {
      yield put(actions.customerRegisterSuccess(userData.data));
      history("/");
    }
  } catch (error) {
    yield put(actions.customerRegisterFailure(error));
  }
}

function* currentUser() {
  try {
    const userData = yield call(api.currentUser);
    if (userData) {
      yield put(actions.currentUserSuccess(userData.data));
    }
  } catch (error) {
    yield put(actions.currentUserFailure(error));
  }
}

function* affiliationAccount() {
  try {
    const affiliationData = yield call(api.userAffiliationAccount);
    if (affiliationData) {
      yield put(actions.affiliationAccountSuccess(affiliationData.data));
    }
  } catch (error) {
    yield put(actions.affiliationAccountFailure(error));
  }
}

function* types() {
  try {
    const typesData = yield call(api.types);
    if (typesData) {
      yield put(actions.typesSuccess(typesData.data));
    }
  } catch (error) {
    yield put(actions.typesFailure(error));
  }
}

function* countries() {
  try {
    const countriesData = yield call(api.countries);
    if (countriesData) {
      yield put(actions.countriesSuccess(countriesData.data));
    }
  } catch (error) {
    yield put(actions.countriesFailure(error));
  }
}

function* addUserNumber({ payload: { data } }) {
  try {
    const userNumber = yield call(api.userNumberAdd, data);
    if (userNumber) {
      yield put(actions.addUserNumberSuccess(userNumber.data));
    }
  } catch (error) {
    yield put(actions.addUserNumberFailure(error));
  }
}

function* updateCustomer({ payload: { data } }) {
  try {
    const userData = yield call(api.updateUserData, data);
    if (userData) {
      yield put(actions.userUpdateSuccess(userData.data));
    }
  } catch (error) {
    yield put(actions.userUpdateFailure(error));
  }
}

function* makeWithdrawalRequest({ payload: { data } }) {
  try {
    const withdrawalRequestResponse = yield call(api.makeWithdrawal, data);
    if (withdrawalRequestResponse) {
      yield put(actions.withdrawalRequestSuccess(withdrawalRequestResponse.data));
    }
  } catch (error) {
    yield put(actions.withdrawalRequestFailure(error));
  }
}


function* watchLogin() {
  yield takeEvery(actionTypes.LOGIN_REQUEST, loginUser);
  yield takeEvery(actionTypes.LOGOUT, logout);
  yield takeEvery(actionTypes.CUSTOMER_REGISTER_REQUEST, customerRegister);
  yield takeEvery(actionTypes.CURRENT_USER_REQUEST, currentUser);
  yield takeEvery(actionTypes.TYPE_COMPTE_REQUEST, types);
  yield takeEvery(actionTypes.COUNTRY_REQUEST, countries);
  yield takeEvery(actionTypes.USER_NUMBER_REQUEST, addUserNumber);
  yield takeEvery(actionTypes.AFFILIATION_ACCOUNT_REQUEST, affiliationAccount);
  yield takeEvery(actionTypes.WITHDRAWAL_REQUEST_REQUEST, makeWithdrawalRequest);
  yield takeEvery(actionTypes.UPDATE_USER_REQUEST, updateCustomer);
}

export default function* authSaga() {
  yield watchLogin();
}
