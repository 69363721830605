import React from 'react';

const Help = () => {
    const redirectToTelegram = () => {
        window.location.href = 'https://t.me/PAYXOF';
    };

    return (
      <div>
        <h1 className="d-flex justify-content-center">Contactez Nous</h1>
        <p className="d-flex justify-content-center">Vous avez une question?</p>
        <p className="d-flex justify-content-around">
        <button
            className="btn btn-primary btn-sm"
            onClick={redirectToTelegram}
        >
            Discutons sur Telegram
        </button>
        </p>
      </div>
    );
};

export default Help;
