import React, { useEffect, useState } from 'react';
import { Input, Label, Button, Spinner, FormFeedback
} from "reactstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { types, customerRegister } from '../store/actions';
import withRouter from "../Routes/withRouter"

const SignupForm = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const parent = queryParameters.get("parent");
  const dispatch = useDispatch();
  const registerError = useSelector((state) => state.auth.error);

  const [errorDisplay, setErrorDisplay] = useState("");

  const countires = [
    { code: "BJ", name: "Bénin" },
    { code: "BF", name: "Burkina Faso" },
    { code: "TG", name: "Togo" },
    { code: "ML", name: "Mali" },
    { code: "NE", name: "Niger" },
    { code: "CT", name: "Cameroon" },
    { code: "SN", name: "Senegal" },
    { code: "CI", name: "Côte d'Ivoire" },
  ]

  const [passwordShow, setPasswordShow] = useState(false);
  const [loadingShow, setloadingShow] = useState(false);
  const typesData = useSelector((state) => state.auth.typesData || []);

  useEffect(() => {
    dispatch(types());
    console.log(registerError)
    if (registerError && registerError.code === "ERR_BAD_REQUEST") {
      if (registerError.response.data?.email) {
        setErrorDisplay(registerError.response.data?.email[0]);
      } else if (registerError.response.data?.phone) {
        setErrorDisplay(registerError.response.data?.phone[0]);
      } else if (registerError.response.data?.message) {
        setErrorDisplay(registerError.response.data?.message);
      }
    } else if (registerError) {
      setErrorDisplay("Une erreur s'est produite lors de l'inscrption de l'utilisateur");
    }
  }, [dispatch, registerError]);

  const [userRegister, setUserRegister] = useState({
    name: "",
    numero: "",
    phone: "",
    type: "",
    pays: "",
    email: "",
    password: '',
    password_confirmation: '',
    affiliation_code: null,
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: userRegister.name,
      numero: userRegister.numero,
      phone: userRegister.phone,
      type: userRegister.type,
      pays: userRegister.pays,
      email: userRegister.email,
      password: userRegister.password,
      password_confirmation: userRegister.password_confirmation,
      affiliation_code: parent && parent.length > 0 ? parent : userRegister.affiliation_code,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Si vous plait entrez votre nom"),
      numero: Yup.string().required("Si vous plait entrez le numéro du compte"),
      phone: Yup.string().required(
        "Si vous plait entrez votre numéro de téléphone"
      ),
      type: Yup.string().required("Si vous plait entrez votre type de compte"),
      pays: Yup.string().required("Si vous plait entrez votre pays"),
      email: Yup.string()
        .email("Si vous plait entrez une adresse email valide")
        .required("Si vous plait entrez votre adresse email"),
      password: Yup.string().required("Si vous plait entrez votre mot de passe"),
      password_confirmation: Yup.string()
        .required("Si vous plait entrez votre mot de passe")
        .equals(
          [Yup.ref("password"), null],
          "Les mots de passe doivent correspondre"
        ),
    }),
    onSubmit: (values) => {
      setloadingShow(true);
      try {
        dispatch(customerRegister(values, props.router.navigate));
        if (registerError && registerError.code === "ERR_BAD_REQUEST") {
          if (registerError.response.data?.email) {
            setErrorDisplay(registerError.response.data?.email[0]);
          } else if (registerError.response.data?.phone) {
            setErrorDisplay(registerError.response.data?.phone[0]);
          } else if (registerError.response.data?.numero) {
            setErrorDisplay(registerError.response.data?.numero[0]);
          }
        } else if (registerError) {
          setErrorDisplay("Une erreur s'est produite lors de la connexion");
        }
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de l'inscription de l'utilisateur :",
          error
        );
        setErrorDisplay(
          `Une erreur s'est produite lors de l'inscription de l'utilisateur`
        );
      } finally {
        setloadingShow(false);
      }
    },
  });
  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <p style={{ fontSize: 26 }}>Inscription</p>
          <p className="mb-0 text-danger">
            Les champs marqués d'un astérisque <strong>(*)</strong> sont
            obligatoires.
          </p>
          <hr></hr>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            className="row g-3"
          >
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Nom & Prénoms<span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                className="form-control"
                id="steparrow-gen-info-username-input"
                placeholder="Enter user name"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name}
                name="name"
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <Label
                className="form-label"
                htmlFor="steparrow-gen-info-type-input"
              >
                Type de compte <span className="text-danger">*</span>
              </Label>
              <select
                className="form-select mb-3"
                aria-label="Selectionner le type de compte"
                id="steparrow-gen-info-type-input"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.type}
                name="type"
              >
                <option value="">Choisir le type de compte</option>
                {typesData.map((type, index) => (
                  <option key={index} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
              {validation.touched.type && validation.errors.type ? (
                <FormFeedback type="invalid">
                  {validation.errors.type}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Numéro de compte
              </label>
              <Input
                type="text"
                className="form-control"
                id="steparrow-gen-info-number-input"
                placeholder="Entrez le numéro de votre compte"
                name="numero"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.numero}
                invalid={
                  validation.touched.numero && validation.errors.numero
                    ? true
                    : false
                }
              />
              {validation.touched.numero && validation.errors.numero ? (
                <FormFeedback type="invalid">
                  {validation.errors.numero}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Pays <span className="text-danger">*</span>
              </label>
              <select
                className="form-select mb-3"
                aria-label="Selectionner le pays"
                id="steparrow-gen-info-countries-input"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.pays}
                name="pays"
              >
                <option defaultValue>Choisir le pays</option>
                {countires.map((country, index) => (
                  <option key={index} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
              {validation.touched.pays && validation.errors.pays ? (
                <FormFeedback type="invalid">
                  {validation.errors.pays}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Numéro de téléphone <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                className="form-control"
                id="steparrow-gen-info-phone-input"
                placeholder="Enter phone"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.phone}
                name="phone"
                invalid={
                  validation.touched.phone && validation.errors.phone
                    ? true
                    : false
                }
              />
              {validation.touched.phone && validation.errors.phone ? (
                <FormFeedback type="invalid">
                  {validation.errors.phone}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Email <span className="text-danger">*</span>
              </label>
              <Input
                type="email"
                className="form-control"
                id="steparrow-gen-info-email-input"
                placeholder="Entrez votre email"
                name="email"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="affiliation_code" className="form-label">
                Code d'affiliation du parent
              </label>
              <Input
                type="affiliation_code"
                className="form-control"
                id="steparrow-gen-info-affiliation_code-input"
                placeholder="Code d'affiliation du parent"
                name="affiliation_code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={parent ? parent : validation.values.affiliation_code}
                invalid={
                  validation.touched.affiliation_code &&
                    validation.errors.affiliation_code
                    ? true
                    : false
                }
                disabled={parent ? true : false}
              />
              {validation.touched.affiliation_code &&
                validation.errors.affiliation_code ? (
                <FormFeedback type="invalid">
                  {validation.errors.affiliation_code}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Mot de passe <span className="text-danger">*</span>
              </label>
              <Input
                name="password"
                value={validation.values.password || ""}
                type={passwordShow ? "text" : "password"}
                className="form-control pe-5"
                placeholder="Entrez votre mot de passe"
                id="steparrow-gen-info-password-input"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
            </div>
            <br />
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Confirmer le mot de passe <span className="text-danger">*</span>
              </label>
              <Input
                type="password"
                className="form-control"
                id="steparrow-gen-info-confirm-password-input"
                placeholder="Enter confirm password"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.password_confirmation}
                name="password_confirmation"
                invalid={
                  validation.touched.password_confirmation &&
                    validation.errors.password_confirmation
                    ? true
                    : false &&
                    validation.values.password_confirmation !==
                    validation.values.password
                }
              />
              {validation.touched.password_confirmation &&
                validation.errors.password_confirmation ? (
                <FormFeedback type="invalid">
                  {validation.errors.password_confirmation}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mt-4">
              <Button
                color="primary"
                disabled={loadingShow ? true : false}
                className="btn btn-primary"
                type="submit"
              >
                {loadingShow ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Changement...{" "}
                  </Spinner>
                ) : null}
                Soumettre
              </Button>
            </div>
            <div className="mt-4">
              {errorDisplay && (
                <div className="alert alert-danger" role="alert">
                  {errorDisplay}
                </div>
              )}
            </div>
          </form>
          <div className="mt-4">
            Vous avez déjà un compte ?
            <Link to={"/signin"} className="btn btn-link">
              Se connecter
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignupForm);
