import { combineReducers } from 'redux';
import authReducer from './auth/reducers';
import demandeReducer from './demande/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  demande: demandeReducer,
});

export default rootReducer;
