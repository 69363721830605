import * as actionTypes from './actionTypes';

const initialState = {
  userData: null,
  loading: false,
  error: null,
  redirectTo: null,
  typesData: null,
  countriesData: null,
  userNumberData: [],
  affiliation: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload?.user,
        loading: false,
        error: null,
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        userData: null,
        loading: false,
        error: action.payload,
      };
    case actionTypes.AFFILIATION_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.AFFILIATION_ACCOUNT_SUCCESS:
      return {
        ...state,
        affiliation: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.AFFILIATION_ACCOUNT_FAILURE:
      return {
        ...state,
        affiliation: {},
        loading: false,
        error: action.payload,
      };
    case actionTypes.WITHDRAWAL_REQUEST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.WITHDRAWAL_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.USER_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.USER_NUMBER_SUCCESS:
      return {
        ...state,
        userNumberData: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.USER_NUMBER_FAILURE:
      return {
        ...state,
        userNumberData: null,
        loading: false,
        error: action.payload,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        userData: null,
        loading: false,
        error: null,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        userData: null,
        loading: false,
        error: null,
      };
    case actionTypes.LOGOUT_FAILURE:
      return {
        ...state,
        userData: null,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CUSTOMER_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.CUSTOMER_REGISTER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.CUSTOMER_REGISTER_FAILURE:
      return {
        ...state,
        userData: null,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        userData: null,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.CURRENT_USER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.CURRENT_USER_FAILURE:
      return {
        ...state,
        userData: null,
        loading: false,
        error: action.payload,
      };
    case actionTypes.TYPE_COMPTE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.TYPE_COMPTE_SUCCESS:
      return {
        ...state,
        typesData: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.TYPE_COMPTE_FAILURE:
      return {
        ...state,
        typesData: null,
        loading: false,
        error: action.payload,
      };
    case actionTypes.COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.COUNTRY_SUCCESS:
      return {
        ...state,
        countriesData: action.payload,
        loading: false,
        error: null,
      }
    case actionTypes.COUNTRY_FAILURE:
      return {
        ...state,
        countriesData: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
