export const DepositMoney = ({ width, height, color }) => {
    return (

        <svg fill={color} width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">

            <g data-name="10. Growth" id="_10._Growth">

                <path d="M17,12.05V11h3a5,5,0,0,0,5-5V4a1,1,0,0,0-1-1H20a4.92,4.92,0,0,0-3,1V1a1,1,0,0,0-2,0V2a4.92,4.92,0,0,0-3-1H8A1,1,0,0,0,7,2V4a5,5,0,0,0,5,5h3v3.05a10,10,0,1,0,2,0Zm3-7h3V6a3,3,0,0,1-3,3H17V8A3,3,0,0,1,20,5ZM9,4V3h3a3,3,0,0,1,3,3V7H12A3,3,0,0,1,9,4Zm7,26a8,8,0,1,1,8-8A8,8,0,0,1,16,30Z" />

                <path d="M16,19h2a1,1,0,0,0,0-2H17a1,1,0,0,0-2,0v.18A3,3,0,0,0,16,23a1,1,0,0,1,0,2H14a1,1,0,0,0,0,2h1a1,1,0,0,0,2,0v-.18A3,3,0,0,0,16,21a1,1,0,0,1,0-2Z" />

                <path d="M5.71,7.29l-2-2a1,1,0,0,0-1.42,0l-2,2A1,1,0,0,0,1.71,8.71L2,8.41V11a1,1,0,0,0,2,0V8.41l.29.3a1,1,0,0,0,1.42,0A1,1,0,0,0,5.71,7.29Z" />

                <path d="M31.71,13.29l-2-2a1,1,0,0,0-1.42,0l-2,2a1,1,0,0,0,1.42,1.42l.29-.3V17a1,1,0,0,0,2,0V14.41l.29.3a1,1,0,0,0,1.42,0A1,1,0,0,0,31.71,13.29Z" />

            </g>

        </svg>
    )
}

export const WithdrawIcon = ({ width, height, color }) => {
    return (
        <svg fill={color} width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">

            <g data-name="1. Money" id="_1._Money">

                <path d="M30,9.18V8a3,3,0,0,0-3-3H22.16a18.57,18.57,0,0,1-5.85-1A20.43,20.43,0,0,0,9.84,3H5A3,3,0,0,0,2,6V7.18A3,3,0,0,0,0,10V24a3,3,0,0,0,3,3H9.84a18.41,18.41,0,0,1,5.84.95A20.45,20.45,0,0,0,22.16,29H29a3,3,0,0,0,3-3V12A3,3,0,0,0,30,9.18ZM5,5H9.84a18.57,18.57,0,0,1,5.85,1,20.43,20.43,0,0,0,6.47,1H27a1,1,0,0,1,1,1V9H22.16a18.41,18.41,0,0,1-5.84-.95A20.45,20.45,0,0,0,9.84,7H4V6A1,1,0,0,1,5,5ZM30,26a1,1,0,0,1-1,1H22.16a18.41,18.41,0,0,1-5.84-.95A20.45,20.45,0,0,0,9.84,25H3a1,1,0,0,1-1-1V10A1,1,0,0,1,3,9H9.84a18.41,18.41,0,0,1,5.84.95A20.45,20.45,0,0,0,22.16,11H29a1,1,0,0,1,1,1Z" />

                <path d="M16,15h2a1,1,0,0,0,0-2H17a1,1,0,0,0-2,0v.18A3,3,0,0,0,16,19a1,1,0,0,1,0,2H14a1,1,0,0,0,0,2h1a1,1,0,0,0,2,0v-.18A3,3,0,0,0,16,17a1,1,0,0,1,0-2Z" />
                {/* <Text x="0" y="20">F cFA</Text> */}
                <path d="M8,16H6a1,1,0,0,0,0,2H8a1,1,0,0,0,0-2Z" />

                <path d="M26,18H24a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />

            </g>

        </svg>
    )
}