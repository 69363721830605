import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { validEmail } from "../utils/validateInfo";
import { BASE_URL } from "../utils/api";

export default function ResetPassword({ history }) {
  const [email, onChangeEmail] = useState("");
  const [code, setCode] = useState("");
  const [uCode, setUCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNPassword, setConfirmNPassword] = useState("");
  const [codeVerified, setCodeVerified] = useState(false);
  const [eReset, setEReset] = useState(false);
  const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

  const reset = () => {
    if (validEmail(email)) {
      setLoading(true);
      try {
        axios
          .post(BASE_URL + "/get/reset-password/code", { identify: email })
          .then((response) => {
            setCode(response.data.data.code);
            setEReset(true);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error, BASE_URL + "/get/reset-password/code");
            setLoading(false);
            alert(error.response.data.message);
          });
      } catch (e) {
        alert("problem when trying to fetch data");
      }
    } else {
      alert("adresse email incorrecte");
    }
  };

  const verifyCode = () => {
    if (code !== uCode) {
      setCodeVerified(false);
      alert("code de vérification incorrect");
      return;
    } else {
      setCodeVerified(true);
    }
  };

  const updatePassword = () => {
    if (newPassword !== confirmNPassword) {
      alert("les mots de passe ne correspondent pas");
      return;
    }
    try {
        setLoading(true);
      axios
        .post(BASE_URL + "/reset-password", {
          identify: email,
          new_password: newPassword,
          code: code,
          confirm_password: confirmNPassword,
        })
        .then((response) => {
          alert("mot de passe mis à jour");
          navigate("/signin");
        })
        .catch((error) => {
          console.log(error.response);
        });
    } catch (e) {
      alert("problem when trying to persist data");
    } finally {
        setLoading(false);
    }
  };

  return (
    <div style={styles.mainBlock}>
      {!eReset ? (
        <div>
          <input
            style={
              validEmail(email)
                ? styles.input
                : { borderColor: "red", ...styles.input }
            }
            onChange={(e) => onChangeEmail(e.target.value)}
            value={email}
            placeholder="Votre adresse email"
          />
          <button
            disabled={loading}
            onClick={reset}
            style={{
              ...styles.button,
              backgroundColor: loading ? "gray" : "#1a5684",
            }}
          >
            {!loading ? "Réinitialiser" : "en cours..."}
          </button>
        </div>
      ) : (
        <div>
          {!codeVerified && (
            <div>
              <input
                style={styles.input}
                onChange={(e) => setUCode(e.target.value)}
                value={uCode}
                placeholder="Code de vérification"
              />
              <button
                disabled={loading}
                onClick={verifyCode}
                style={{
                  ...styles.button,
                  backgroundColor: loading ? "gray" : "#1a5684",
                }}
              >
                Continuer
              </button>
            </div>
          )}
          {codeVerified && (
            <div>
              <input
                style={styles.input}
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                placeholder="Nouveau mot de passe"
                type="password"
              />
              <input
                style={styles.input}
                onChange={(e) => setConfirmNPassword(e.target.value)}
                value={confirmNPassword}
                placeholder="Confirmer le mot de passe"
                type="password"
              />
              <button
                disabled={loading}
                onClick={updatePassword}
                style={{
                  ...styles.button,
                  backgroundColor: loading ? "gray" : "#1a5684",
                }}
              >
                {!loading ? "Confirmer" : "en cours..."}
              </button>
            </div>
          )}
        </div>
      )}
      {eReset && (
        <p style={{ textAlign: "center", color: "rgba(34, 184, 11, 0.699)" }}>
          Un mail de réinitialisation vous a été envoyé. Vérifiez vos spams si
          vous ne recevez pas le mail.
        </p>
      )}
    </div>
  );
}

const styles = {
  mainBlock: {
    width: "100%",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "white",
    padding: "2rem",
  },
  input: {
    borderWidth: "2px",
    borderRadius: "10px",
    padding: "15px",
    borderColor: "#eeeeee",
    width: "100%",
    height: "60px",
    fontFamily: "LeagueSpartan400",
    fontSize: "20px",
    marginBottom: "1rem",
  },
  button: {
    borderRadius: "10px",
    padding: "10px",
    height: "60px",
    fontFamily: "LeagueSpartan600",
    fontSize: "22px",
    color: "white",
    cursor: "pointer",
  },
};
