import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import demandeSaga from './demande/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    demandeSaga(),
  ]);
}
