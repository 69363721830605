import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../contexts/auth-context";
import { useDispatch, useSelector } from 'react-redux';
import { Input, FormFeedback, Button, Spinner } from 'reactstrap'
import { createDemandeRetrait, currentUser } from '../store/actions';
import { useNavigate } from 'react-router-dom';

const Withdraw = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const { user } = useAuth();

  const [loadingShow, setloadingShow] = useState(false);
  const _currentUser = useSelector((state) => state.auth.userData || {});
  const error = useSelector((state) => state.demande.error || null);

   const [errorMessage, setErrorMessage] = useState("");
   const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    dispatch(currentUser());
  }, [dispatch]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type_compte: '',
      numero: '',
      amount: '',
      reseau: '',
      receiver_or_sender_number: user ? user.phone : '',
      transaction: '',
      receiver_name: user ? user.name : '',
    },
    validationSchema: Yup.object({
      type_compte: Yup.string().required("Veuillez sélectionner le type de compte"),
      numero: Yup.string().required("Veuillez entrer le numéro du compte"),
      amount: Yup.number().required("Veuillez entrer le montant").min(1000, "Le montant doit être supérieur ou égal à 1000"),
      reseau: Yup.string().required("Veuillez sélectionner le type de réseau"),
      receiver_or_sender_number: Yup.number().required("Veuillez entrer le numéro de téléphone du bénéficiaire"),
      transaction: Yup.string().required("Veuillez entrer le code de retrait"),
      receiver_name: Yup.string().required("Veuillez entrer le nom du receiver"),
    }),
    onSubmit: (values) => {
      setloadingShow(true);
      const formData = { ...values, userId: user };
      dispatch(createDemandeRetrait(formData));
      if (error?.message) {
        setErrorMessage(error?.message);
        setSuccessMessage("");
        setloadingShow(false);
        return;
      }
      setSuccessMessage("Demande de retrait envoyée avec succès");
      setErrorMessage("");
      setloadingShow(false);
      setTimeout(() => {
        navigate("/history");
      }, 2000);
    },
  });

  const handleTypeCompteChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    validation.handleChange(event);

    if (_currentUser && _currentUser.user_number && Array.isArray(_currentUser.user_number)) {
      const foundUserNumber = _currentUser.user_number.find((userNumber, key) => key === (selectedIndex-1));
      console.log(foundUserNumber)
      if (foundUserNumber) {
        validation.setFieldValue("numero", foundUserNumber.numero);
        return;
      } else {
        validation.setFieldValue("numero", '');
        return;
      }
    } else {
      validation.setFieldValue("numero", '');
      return;
    }
  };

  return (
    <div className="container mt-4">
      <div className="row  mt-4">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6">
              <p style={{ fontSize: 26 }}>Faire un retrait</p>
            </div>
            <div className="col-sm-6 text-end">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#commentCaMarche"
                className="btn btn-primary btn-lg"
              >
                Comment ça marche marche ?
              </button>
            </div>
            <div
              className="modal fade"
              id="commentCaMarche"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">
                      comment effectuer un retrait ?
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>🖊 Première étape</p>
                    <p>1- Retirer</p>
                    <p>
                      2- Choisir 1xbet espece (pour 1XBET) et 1WIN cash (pour
                      1WIN)
                    </p>
                    <p>
                      3- Saisissez le montant puis Choisissez la ville : Cotonou
                      (Littoral) , Rue : A PAYXOF pour 1XBET et Cotonou, Rue :
                      Payxof pour 1WIN puis confirmez
                    </p>
                    <p>
                      4- Saisissez dans la nouvelle fenêtre le code SMS / mail
                      reçu.
                    </p>
                    <p>
                      5- Patientez quelques minutes , que votre retrait soit
                      approuvé puis allez sur profil, et cliquez sur détail que
                      obtenir votre code de retrait à fournir dans votre demande
                      sur PayXOF
                    </p>
                    <p>🖊 Deuxième étape</p>
                    <p>
                      1- Connectez-vous à votre compte PayXOF et remplissez le
                      formulaire de retrait en renseignant les informations
                      demandées.
                    </p>
                    <p>2- Cliquer sur Soumettre la demande</p>
                    <p>
                      Attendre quelques secondes afin que votre demande soit
                      traitée
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="mb-0 text-danger">
            Attention ⚠️ {"\n"}Le montant minimum autorisé est 1000FCFA. Tout
            retrait inférieur à ce montant ne sera pas pris en compte.
          </p>
          <p className="mb-0 text-danger">
            Les champs marqués d'un astérisque <strong>(*)</strong> sont
            obligatoires.
          </p>
          <hr></hr>
          <form onSubmit={validation.handleSubmit} className="row g-3">
            <div className="col-12">
              <label className="form-label">
                Type de compte <span className="text-danger">*</span>
              </label>
              <select
                className="form-select mb-3"
                aria-label="Selectionner le type de compte"
                id="steparrow-gen-info-type-input"
                required
                onChange={handleTypeCompteChange}
                onBlur={validation.handleBlur}
                value={validation.values.type_compte}
                name="type_compte"
              >
                <option value="">Choisir le type de compte</option>
                {_currentUser?.user_number?.map((userNumber, index) => (
                  <option key={index} value={userNumber.type.id}>
                    {userNumber.type.name} ({userNumber.numero})
                  </option>
                ))}
              </select>
              {validation.touched.type_compte &&
              validation.errors.type_compte ? (
                <FormFeedback type="invalid">
                  {validation.errors.type_compte}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="numberInput" className="form-label">
                Numéro du compte <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                className="form-control"
                id="numberInput"
                name="numero"
                placeholder="Entrer le numéro du compte"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.numero}
              />
              {validation.touched.numero && validation.errors.numero && (
                <div className="text-danger">{validation.errors.numero}</div>
              )}
            </div>
            <div className="col-12">
              <label htmlFor="amountInput" className="form-label">
                Montant <span className="text-danger">*</span>
              </label>
              <Input
                type="number"
                className="form-control"
                id="amountInput"
                placeholder="Entrer le montant"
                name="amount"
                min={1}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.amount}
              />
              {validation.touched.amount && validation.errors.amount && (
                <div className="text-danger">{validation.errors.amount}</div>
              )}
            </div>
            <div className="col-12">
              <label htmlFor="fullName" className="form-label">
                Type de réseau <span className="text-danger">*</span>
              </label>
              <select
                className="form-select mb-3"
                aria-label="Selectionner le type de réseau"
                id="steparrow-gen-info-type-input"
                name="reseau"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reseau}
              >
                <option value="">Choisir le type de réseau </option>
                <option value="MTN">MTN Mobile Money</option>
                <option value="MOOV">Moov Money</option>
              </select>
              {validation.touched.reseau && validation.errors.reseau && (
                <div className="text-danger">{validation.errors.reseau}</div>
              )}
            </div>
            <div className="col-12">
              <label htmlFor="transactionInput" className="form-label">
                Code de retrait <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                className="form-control"
                id="transactionInput"
                placeholder="Entrer le code de retrait"
                name="transaction"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.transaction}
              />
              {validation.touched.transaction &&
                validation.errors.transaction && (
                  <div className="text-danger">
                    {validation.errors.transaction}
                  </div>
                )}
            </div>
            <div className="col-12">
              <label htmlFor="expeditorInput" className="form-label">
                Téléphone du bénéficiaire <span className="text-danger">*</span>
              </label>
              <Input
                type="number"
                className="form-control"
                id="expeditorInput"
                placeholder="Entrer le numéro de téléphone du bénéficiaire"
                name="receiver_or_sender_number"
                min={1}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.receiver_or_sender_number}
              />
              {validation.touched.receiver_or_sender_number &&
                validation.errors.receiver_or_sender_number && (
                  <div className="text-danger">
                    {validation.errors.receiver_or_sender_number}
                  </div>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="receiverInput" className="form-label">
                Nom du réceveur <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                className="form-control"
                id="receiverInput"
                placeholder="Entrer le Nom du réceveur"
                name="receiver_name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.receiver_name}
              />
              {validation.touched.receiver_name &&
                validation.errors.receiver_name && (
                  <div className="text-danger">
                    {validation.errors.receiver_name}
                  </div>
                )}
            </div>
            <div className="col-12">
              {errorMessage !== "" && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              {successMessage !== "" && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
            </div>
            <div className="text-end">
              <Button
                color="primary"
                disabled={loadingShow ? true : false}
                className="btn btn-primary"
                type="submit"
              >
                {loadingShow ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Changement...{" "}
                  </Spinner>
                ) : null}
                Soumettre la demande
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;

