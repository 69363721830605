// actionTypes.js

export const CREATE_DEMANDE_RECHARGE_REQUEST = 'CREATE_DEMANDE_RECHARGE_REQUEST';
export const CREATE_DEMANDE_RECHARGE_SUCCESS = 'CREATE_DEMANDE_RECHARGE_SUCCESS';
export const CREATE_DEMANDE_RECHARGE_FAILURE = 'CREATE_DEMANDE_RECHARGE_FAILURE';

export const CREATE_DEMANDE_RETRAIT_REQUEST = 'CREATE_DEMANDE_RETRAIT_REQUEST';
export const CREATE_DEMANDE_RETRAIT_SUCCESS = 'CREATE_DEMANDE_RETRAIT_SUCCESS';
export const CREATE_DEMANDE_RETRAIT_FAILURE = 'CREATE_DEMANDE_RETRAIT_FAILURE';

export const FETCH_DEMANDE_DETAIL_REQUEST = 'FETCH_DEMANDE_DETAIL_REQUEST';
export const FETCH_DEMANDE_DETAIL_SUCCESS = 'FETCH_DEMANDE_DETAIL_SUCCESS';
export const FETCH_DEMANDE_DETAIL_FAILURE = 'FETCH_DEMANDE_DETAIL_FAILURE';

export const FETCH_DEMANDES_FILTER_LISTS_REQUEST = 'FETCH_DEMANDES_FILTER_LISTS_REQUEST';
export const FETCH_DEMANDES_FILTER_LISTS_SUCCESS = 'FETCH_DEMANDES_FILTER_LISTS_SUCCESS';
export const FETCH_DEMANDES_FILTER_LISTS_FAILURE = 'FETCH_DEMANDES_FILTER_LISTS_FAILURE';

export const FETCH_CUSTOMER_HISTORY_DEMANDES_REQUEST = 'FETCH_CUSTOMER_HISTORY_DEMANDES_REQUEST';
export const FETCH_CUSTOMER_HISTORY_DEMANDES_SUCCESS = 'FETCH_CUSTOMER_HISTORY_DEMANDES_SUCCESS';
export const FETCH_CUSTOMER_HISTORY_DEMANDES_FAILURE = 'FETCH_CUSTOMER_HISTORY_DEMANDES_FAILURE';

export const FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_REQUEST = 'FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_REQUEST';
export const FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_SUCCESS = 'FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_SUCCESS';
export const FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_FAILURE = 'FETCH_CUSTOMER_LIST_DEMANDE_BY_TYPE_FAILURE';

export const UPDATE_DEMANDE_REQUEST = 'UPDATE_DEMANDE_REQUEST';
export const UPDATE_DEMANDE_SUCCESS = 'UPDATE_DEMANDE_SUCCESS';
export const UPDATE_DEMANDE_FAILURE = 'UPDATE_DEMANDE_FAILURE';

export const DELETE_DEMANDE_REQUEST = 'DELETE_DEMANDE_REQUEST';
export const DELETE_DEMANDE_SUCCESS = 'DELETE_DEMANDE_SUCCESS';
export const DELETE_DEMANDE_FAILURE = 'DELETE_DEMANDE_FAILURE';

export const CANCEL_DEMANDE_REQUEST = 'CANCEL_DEMANDE_REQUEST';
export const CANCEL_DEMANDE_SUCCESS = 'CANCEL_DEMANDE_SUCCESS';
export const CANCEL_DEMANDE_FAILURE = 'CANCEL_DEMANDE_FAILURE';

export const BEGIN_TREATMENT_DEMANDE_REQUEST = 'BEGIN_TREATMENT_DEMANDE_REQUEST';
export const BEGIN_TREATMENT_DEMANDE_SUCCESS = 'BEGIN_TREATMENT_DEMANDE_SUCCESS';
export const BEGIN_TREATMENT_DEMANDE_FAILURE = 'BEGIN_TREATMENT_DEMANDE_FAILURE';

export const FETCH_AGENT_HISTORY_TRAITY_DEMANDE_REQUEST = 'FETCH_AGENT_HISTORY_TRAITY_DEMANDE_REQUEST';
export const FETCH_AGENT_HISTORY_TRAITY_DEMANDE_SUCCESS = 'FETCH_AGENT_HISTORY_TRAITY_DEMANDE_SUCCESS';
export const FETCH_AGENT_HISTORY_TRAITY_DEMANDE_FAILURE = 'FETCH_AGENT_HISTORY_TRAITY_DEMANDE_FAILURE';

export const FETCH_MY_ACCOUNT_REQUEST = 'FETCH_MY_ACCOUNT_REQUEST';
export const FETCH_MY_ACCOUNT_SUCCESS = 'FETCH_MY_ACCOUNT_SUCCESS';
export const FETCH_MY_ACCOUNT_FAILURE = 'FETCH_MY_ACCOUNT_FAILURE';
