
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../contexts/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { Input, FormFeedback, Button, Spinner } from 'reactstrap'
import {
  createDemandeRecharge,
  createDemandeRechargeFailure,
  currentUser,
  types,
} from "../store/actions";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ApiResponse } from '../contexts/api-responses';
/*import {
  getLatestTransactions,
  canPreocessTransaction,
} from "../utils/latestsTransactions";*/

const Deposit = () => {
  const apiResponses = useContext(ApiResponse);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [loadingShow, setloadingShow] = useState(false);
  const _currentUser = useSelector((state) => state.auth.userData || {});

  //console.log(apiResponses);
  const typesData = useSelector((state) => state.auth.typesData || []);
  const error = useSelector((state) => state.demande.error || null);
  const rechargeSuccess = useSelector((state) => state.demande.rechargeSuccess || false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [latesttwo, setLatestTwo] = useState([]);
  const [canDoTransac, setCanDoTransac] = useState(false);

  const [MoMo, setMoMo] = useState("");

  useEffect(() => {
    dispatch(currentUser());
    dispatch(types());
  }, [dispatch]);

  /*useEffect(() => {
    const getTransacs = async () => {
      const latestTransactions = await getLatestTransactions(user, 2);
      setLatestTwo(latestTransactions);
      const canProcess = canPreocessTransaction(latestTransactions);
      setCanDoTransac(canProcess);
    }
    getTransacs();
  }, [latesttwo, canDoTransac, user]);*/


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type_compte: "",
      numero: "",
      amount: "",
      reseau: "",
      receiver_or_sender_number: _currentUser.phone ? _currentUser.phone : "",
      transaction: "",
    },

    validationSchema: Yup.object({
      type_compte: Yup.string().required(
        "Veuillez sélectionner le type de compte"
      ),
      numero: Yup.string().required("Veuillez entrer le numéro du compte"),
      amount: Yup.number()
        .required("Veuillez entrer le montant")
        .min(1000, "Le montant doit être supérieur ou égal à 1000FCFA"),
      reseau: Yup.string().required("Veuillez sélectionner le type de réseau"),
      receiver_or_sender_number: Yup.number().required(
        "Veuillez entrer le numéro de téléphone de l'expéditeur"
      ),
      transaction: Yup.number()
        .typeError("Veuillez entrer un numéro de transaction valide")
        .min(0)
        .test(
          "noEOrSign", // type of the validator (should be unique)
          "signes ou exposant non autorisés", // error message
          (value) =>
            typeof value === "number" && !/[eE+-.]/.test(value.toString())
        )
        .required("Veuillez entrer le numéro de transaction"),
    }),

    onSubmit: async (values) => {
      setloadingShow(true);
      const formData = { ...values, userId: user };
      await dispatch(createDemandeRecharge(formData));
      if (error?.message) {
        setSuccessMessage("");
        setErrorMessage(error?.message);
        setloadingShow(false);
        return;
      } else {
        setErrorMessage("");
        setSuccessMessage("Demande de dépôt ennvoyée avec succès");
        setloadingShow(false);
        setTimeout(() => {
          navigate("/history");
        }, 2000);
      }
    },
  });

  const handleTypeCompteChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    validation.handleChange(event);

    if (_currentUser && _currentUser.user_number && Array.isArray(_currentUser.user_number)) {
      const foundUserNumber = _currentUser.user_number.find((userNumber, key) => key === (selectedIndex-1));
      //console.log(foundUserNumber)
      if (foundUserNumber) {
        validation.setFieldValue("numero", foundUserNumber.numero);
        return;
      } else {
        validation.setFieldValue("numero", '');
        return;
      }
    } else {
      validation.setFieldValue("numero", '');
      return;
    }
  };

  const handleNumeroCompteChange = (event) => {
    validation.handleChange(event);
  }

   const linkNumber = (reseau, amount) => {
     if (reseau === "MOOV") {
       setMoMo(`tel:*855*4*1*73813*${amount}${"#"}`);
     } else {
      setMoMo(`tel:*880*41*444911*${amount}${"#"}`);
     }
   };


  return (
    <div className="container mt-4">
      <div className="row  mt-4">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          {/*!canDoTransac ? (
            <div className="alert alert-warning" role="alert">
              Vous ne pouvez pas effectuer une nouvelle demande de dépôt pour le
              moment. Veuillez réessayer dans quelques instants.
            </div>
          ) : null*/}
          <div className="row">
            <div className="col-sm-6">
              <p style={{ fontSize: 26 }}>Faire un dépôt</p>
            </div>
            <div className="col-sm-6 text-end">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#commentCaMarche"
                className="btn btn-primary btn-lg"
              >
                Comment ça marche marche ?
              </button>
            </div>
            <div
              className="modal fade"
              id="commentCaMarche"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">
                      comment effectuer un dépôt ?
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>
                      🖊 Remplissez le formulaire en suivant attentivement les
                      instructions
                    </p>
                    <p>
                      🖊 Effectuer un paiement du montant à recharger en tapant
                      *880*41*444911*montant# (MTN MoMo) ou
                      *855*4*1*73813*montant# (MOOV Money) Nom: PayXOF
                    </p>
                    <p>🖊 Soumettre la demande de recharge</p>
                    <p>
                      Attendre quelques secondes afin que votre demande soit
                      traitée
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="mb-0 text-danger">
            Attention ⚠️ {"\n"}Le montant minimum autorisé est 1000FCFA. Tout
            dépôt inférieur à ce montant ne sera pas pris en compte.
          </p>
          <p className="mb-0 text-danger">
            Les champs marqués d'un astérisque <strong>(*)</strong> sont
            obligatoires.
          </p>
          <hr></hr>
          <form onSubmit={validation.handleSubmit} className="row g-3">
            <div className="col-12">
              <label className="form-label">
                Type de compte <span className="text-danger">*</span>
              </label>
              <select
                className="form-select mb-3"
                aria-label="Selectionner le type de compte"
                id="steparrow-gen-info-type-input"
                required
                onChange={handleTypeCompteChange}
                onBlur={validation.handleBlur}
                value={validation.values.type_compte}
                name="type_compte"
              >
                <option value="">Choisir le type de compte</option>
                {typesData?.map((type, index) => (
                  <option key={index} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
              {validation.touched.type_compte &&
              validation.errors.type_compte ? (
                <FormFeedback type="invalid">
                  {validation.errors.type_compte}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="numberInput" className="form-label">
                Numéro du compte <span className="text-danger">*</span>
              </label>
              <select
                className="form-select mb-3"
                aria-label="Selectionner le numéro du compte"
                id="numberInput"
                required
                onChange={handleNumeroCompteChange}
                onBlur={validation.handleBlur}
                value={validation.values.numero}
                name="numero"
              >
                <option value="">Choisir le numéro de compte</option>
                {_currentUser?.user_number?.map(
                  (number, index) =>
                    parseInt(number.type.id) ===
                      parseInt(validation.values.type_compte) && (
                      <option key={index} value={number.numero}>
                        {number.numero}
                      </option>
                    )
                )}
              </select>
            </div>
            <div className="col-12">
              <label htmlFor="amountInput" className="form-label">
                Montant <span className="text-danger">*</span>
              </label>
              <Input
                type="number"
                className="form-control"
                id="amountInput"
                placeholder="Entrer le montant"
                name="amount"
                min={1}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.amount}
              />
              {validation.touched.amount && validation.errors.amount && (
                <div className="text-danger">{validation.errors.amount}</div>
              )}
            </div>
            <div className="col-12">
              <label htmlFor="fullName" className="form-label">
                Type de réseau <span className="text-danger">*</span>
              </label>
              <select
                className="form-select mb-3"
                aria-label="Selectionner le type de réseau"
                id="steparrow-gen-info-type-input"
                name="reseau"
                required
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reseau}
              >
                <option value="">Choisir le type de réseau </option>
                <option value="MTN">MTN Mobile Money</option>
                <option value="MOOV">Moov Money</option>
              </select>
              {validation.touched.reseau && validation.errors.reseau && (
                <div className="text-danger">{validation.errors.reseau}</div>
              )}
            </div>
            <div className="col-12">
              <label htmlFor="expeditorInput" className="form-label">
                Téléphone de l'expéditeur <span className="text-danger">*</span>
              </label>
              <Input
                type="number"
                className="form-control"
                id="expeditorInput"
                placeholder="Entrer le numéro de téléphone de l'expéditeur"
                name="receiver_or_sender_number"
                min={1}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.receiver_or_sender_number}
              />
              {validation.touched.receiver_or_sender_number &&
                validation.errors.receiver_or_sender_number && (
                  <div className="text-danger">
                    {validation.errors.receiver_or_sender_number}
                  </div>
                )}
            </div>
            <div className="col-12">
              <label htmlFor="transactionInput" className="form-label">
                Id de la transaction mobile money{" "}
                <span className="text-danger">*</span>
              </label>
              <Input
                type="text"
                className="form-control"
                id="transactionInput"
                placeholder="Entrer l'ID de la transaction"
                name="transaction"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.transaction
                  .trim()
                  .replace(" ", "")
                  .replace(".", "")
                  .replace("+", "")
                  .replace("-", "")
                  .replace("e", "")}
              />
              {validation.touched.transaction &&
                validation.errors.transaction && (
                  <div className="text-danger">
                    {validation.errors.transaction}
                  </div>
                )}
            </div>
            {validation.values.reseau &&
              validation.values.amount >= 1000 && (
                <div className="col-12">
                  Avant de continuer, faites un dépôt de{" "}
                  <span className="text-danger">
                    {validation.values.amount} F CFA
                  </span>{" "}
                  puis fournir en bas l'ID de la transaction dans le champs
                  "Numéro de la transaction"
                  <br />
                  <a
                    href={
                      validation.values.reseau === "MOOV"
                        ? `tel:*855*4*1*73813*${validation.values.amount}${"#"}`
                        : `tel:*880*41*444911*${validation.values.amount}${"#"}`
                    }
                  >
                    Cliquez ici pour effectuer le dépôt
                  </a>
                  <br />
                  Nom du marchant:
                  <span className="text-danger"> PAYXOF</span>
                </div>
              )}
            <div className="col-12">
              {errorMessage !== "" && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              {successMessage !== "" && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
            </div>
              <div className="text-end">
                <Button
                  color="primary"
                  disabled={loadingShow ? true : false}
                  className="btn btn-primary"
                  type="submit"
                >
                  {loadingShow ? (
                    <Spinner size="sm" className="me-2">
                      Changement..
                    </Spinner>
                  ) : null}
                  Soumettre la demande
                </Button>
              </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Deposit;

