import { axiosWithoutToken } from "./api";
import axiosWithToken from "./api";

export const login = data => {
  return axiosWithoutToken.post('login', data);
};

export const userNumberAdd = data => {
  return axiosWithToken.post('add-user-number/' + data.userId, data);
};

export const updateUserData = data => {
  return axiosWithToken.put('customer/'+  data.userId +'/update', data);
};

export const makeWithdrawal = data => {
  return axiosWithToken.post('withdrawal/recompense/' + data.userId, data);
};

export const logout = () => {
  return axiosWithToken.post('logout');
};

export const customerRegister = data => {
  return axiosWithoutToken.post('customer/register', data);
};

export const currentUser = () => {
  return axiosWithToken.get('current-user');
};

export const userAffiliationAccount = () => {
  return axiosWithToken.get('my-affiliation/account');
};

export const types = () => {
  return axiosWithToken.get('types');
};

export const countries = () => {
  return axiosWithToken.get('countries');
};
