// Signin.js

import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { FormFeedback, Input, Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser } from "../store/actions";

const Signin = () => {
  const dispatch = useDispatch();
  const userCurrent = useSelector((state) => state.auth.userData);
  const loginError = useSelector((state) => state.auth.error);

  const { authenticate, assignRole } = useAuth();

  const [userLogin, setUserLogin] = useState({
    email_or_phone: "",
    password: "",
  });

  const [loadingShow, setloadingShow] = useState(false);
  const [error, setError] = useState(null);

  const [errorDisplay, setErrorDisplay] = useState('');

  useEffect(() => {
    setUserLogin({
      email_or_phone: "",
      password: "",
    });
    if (loginError && loginError.code === "ERR_BAD_REQUEST") {
      setErrorDisplay("Identifiant ou mot de passe incorrect");
    } else if (loginError) {
      setErrorDisplay("Une erreur s'est produite lors de la connexion");
    }
  }, [dispatch, loginError]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userCurrent) {
          authenticate(userCurrent.id);
          assignRole(userCurrent.role.name);
        }
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la connexion de l'utilisateur :",
          error
        );
        setError(
          `Une erreur s'est produite lors de la connexion de l'utilisateur: ${error.message}`
        );
      }
    };
    fetchData();
  }, [dispatch, userCurrent, authenticate, assignRole]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email_or_phone: userLogin.email_or_phone,
      password: userLogin.password,
    },
    validationSchema: Yup.object({
      email_or_phone: Yup.string().required(
        "Si vous plait entrez votre adresse email ou votre numéro de téléphone"
      ),
      password: Yup.string().required(
        "Si vous plait entrez votre mot de passe"
      ),
    }),
    onSubmit: async (values) => {
      setloadingShow(true);
      try {
        // Dispatch the login action and await its completion
         await dispatch(loginUser(values));
         if (loginError && loginError.code === "ERR_BAD_REQUEST") {
           setErrorDisplay("Identifiant ou mot de passe incorrect");
         } else if (loginError) {
           setErrorDisplay("Une erreur s'est produite lors de la connexion");
         } 
        // If login is successful, set loading to false
      } catch (error) {
        // If an error occurs during login, set loading to false
        console.error("Impossible de se connecter", error);
      } finally {
        setloadingShow(false);
      }
    },
  });

  return (
    <div className="container mt-4">
      <div className="row mt-4">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <h2>Connexion</h2>
          <hr />
          <form
            className="row g-3"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Téléphone ou adresse email
              </label>
              <Input
                className="form-control"
                name="email_or_phone"
                type="text"
                placeholder="Téléphone / Email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email_or_phone || ""}
                invalid={
                  validation.touched.email_or_phone &&
                  validation.errors.email_or_phone
                    ? true
                    : false
                }
              />
              {validation.touched.email_or_phone &&
              validation.errors.email_or_phone ? (
                <FormFeedback type="invalid">
                  {validation.errors.email_or_phone}
                </FormFeedback>
              ) : null}
            </div>
            <div className="col-12">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Mot de passe
              </label>
              <Input
                className="form-control"
                id="password"
                type="password"
                placeholder="******************"
                value={validation.values.password || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  {validation.errors.password}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mt-4">
              <Button
                color="primary"
                disabled={loadingShow ? true : false}
                className="btn btn-primary"
                type="submit"
              >
                {loadingShow ? (
                  <Spinner size="sm" className="me-2">
                    Changement...
                  </Spinner>
                ) : null}
                Se connecter
              </Button>
            </div>
            <div className="mt-4">
              {errorDisplay && (
                <div className="alert alert-danger" role="alert">
                  {errorDisplay}
                </div>
              )}
            </div>
          </form>
          <div className="mt-4">
            Mot de passe oublié?
            <a id="emailHelp" className="form-text btn btn-link" href="/reset">
              Réinitialiser
            </a>
          </div>
          <div className="mt-4">
            Vous n'avez pas de compte ?
            <Link to={"/signup"} className="btn btn-link">
              Créer un compte
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
