import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from "./store";
import App from './App';
import { useContext } from 'react';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./contexts/auth-context";
import { ApiResponse } from './contexts/api-responses';



const store = configureStore({});

const Container = () => {
  const apiResponses = useContext(ApiResponse);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <AuthProvider>
          <ApiResponse.Provider value={apiResponses}>
            <App />
          </ApiResponse.Provider>
        </AuthProvider>
      </Provider>
    </React.StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Container />);

reportWebVitals();