import './App.css';
import { useAuth } from "./contexts/auth-context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layouts/Layout";
import LayoutOut from "./Layouts/LayoutOut";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";
import NoPage from "./Pages/NoPage";
import Deposit from "./Pages/Deposit";
import Withdraw from "./Pages/Withdraw";
import History from "./Pages/History";
import Home from './Pages/Home';
import Account from './Pages/Account';
import Help from './Pages/Help';
import ResetPassword from './Pages/ResetPassword';

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter basename='/'>
      <Routes>
        {!isAuthenticated ? (
          <Route path="/" element={<LayoutOut />}>
            <Route index element={<Home />} />
            <Route path="signin" element={<Signin />} />
            <Route path="signup" element={<Signup />} />
            <Route path="help" element={<Help />} />
            <Route path="reset" element={<ResetPassword />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        ) : (
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="deposit" element={<Deposit />} />
            <Route path="withdraw" element={<Withdraw />} />
            <Route path="history" element={<History />} />
            <Route path="account" element={<Account />} />
            <Route path="help" element={<Help />} />
            <Route path="*" element={<Home />} />

          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
