import * as actionTypes from './actionTypes';

export const loginUser = (data) => ({
  type: actionTypes.LOGIN_REQUEST,
  payload: {data},
});

export const loginSuccess = (userData) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: userData,
});

export const loginFailure = (error) => ({
  type: actionTypes.LOGIN_FAILURE,
  payload: error,
});

export const affiliationAccount = () => ({
  type: actionTypes.AFFILIATION_ACCOUNT_REQUEST,
  payload: {},
});

export const affiliationAccountSuccess = (affiliationAccount) => ({
  type: actionTypes.AFFILIATION_ACCOUNT_SUCCESS,
  payload: affiliationAccount,
});

export const affiliationAccountFailure = (error) => ({
  type: actionTypes.AFFILIATION_ACCOUNT_FAILURE,
  payload: error,
});

export const withdrawalRequest = (data) => ({
  type: actionTypes.WITHDRAWAL_REQUEST_REQUEST,
  payload: {data},
});

export const withdrawalRequestSuccess = (withdrawalRequest) => ({
  type: actionTypes.WITHDRAWAL_REQUEST_SUCCESS,
  payload: withdrawalRequest,
});

export const withdrawalRequestFailure = (error) => ({
  type: actionTypes.WITHDRAWAL_REQUEST_FAILURE,
  payload: error,
});

export const userUpdate = (data) => ({
  type: actionTypes.UPDATE_USER_REQUEST,
  payload: {data},
});

export const userUpdateSuccess = (userUpdate) => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  payload: userUpdate,
});

export const userUpdateFailure = (error) => ({
  type: actionTypes.UPDATE_USER_FAILURE,
  payload: error,
});

export const addUserNumber = (data) => ({
  type: actionTypes.USER_NUMBER_REQUEST,
  payload: {data},
});

export const addUserNumberSuccess = (userUnumberData) => ({
  type: actionTypes.USER_NUMBER_SUCCESS,
  payload: userUnumberData,
});

export const addUserNumberFailure = (error) => ({
  type: actionTypes.USER_NUMBER_FAILURE,
  payload: error,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
  payload: {},
});

export const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS,
  payload: {},
});

export const logoutFailure = (error) => ({
  type: actionTypes.LOGOUT_FAILURE,
  payload: error,
});

export const customerRegister = (data, history) => ({
  type: actionTypes.CUSTOMER_REGISTER_REQUEST,
  payload: {data, history},
});

export const customerRegisterSuccess = (userData) => ({
  type: actionTypes.CUSTOMER_REGISTER_SUCCESS,
  payload: {},
});

export const customerRegisterFailure = (error) => ({
  type: actionTypes.CUSTOMER_REGISTER_FAILURE,
  payload: error,
});

export const currentUser = () => ({
  type: actionTypes.CURRENT_USER_REQUEST,
  payload: {},
});

export const currentUserSuccess = (userData) => ({
  type: actionTypes.CURRENT_USER_SUCCESS,
  payload: userData,
});

export const currentUserFailure = (error) => ({
  type: actionTypes.CURRENT_USER_FAILURE,
  payload: error,
});

export const types = () =>({
  type: actionTypes.TYPE_COMPTE_REQUEST,
  payload: {},
})

export const typesSuccess = (typesData) => ({
  type: actionTypes.TYPE_COMPTE_SUCCESS,
  payload: typesData,
});

export const typesFailure = (error) => ({
  type: actionTypes.TYPE_COMPTE_FAILURE,
  payload: error,
});

export const countries = () => ({
  type: actionTypes.COUNTRY_REQUEST,
  payload: {},
});

export const countriesSuccess = (countriesData) => ({
  type: actionTypes.COUNTRY_SUCCESS,
  payload: countriesData,
});

export const countriesFailure = (error) => ({
  type: actionTypes.COUNTRY_FAILURE,
  payload: error,
});