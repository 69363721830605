export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const USER_NUMBER_REQUEST = 'USER_NUMBER_REQUEST';
export const USER_NUMBER_SUCCESS = 'USER_NUMBER_SUCCESS';
export const USER_NUMBER_FAILURE = 'USER_NUMBER_FAILURE';

export const AFFILIATION_ACCOUNT_REQUEST = 'AFFILIATION_ACCOUNT_REQUEST';
export const AFFILIATION_ACCOUNT_SUCCESS = 'AFFILIATION_ACCOUNT_SUCCESS';
export const AFFILIATION_ACCOUNT_FAILURE = 'AFFILIATION_ACCOUNT_FAILURE';

export const WITHDRAWAL_REQUEST_REQUEST = 'WITHDRAWAL_REQUEST_REQUEST';
export const WITHDRAWAL_REQUEST_SUCCESS = 'WITHDRAWAL_REQUEST_SUCCESS';
export const WITHDRAWAL_REQUEST_FAILURE = 'WITHDRAWAL_REQUEST_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const CUSTOMER_REGISTER_REQUEST = 'CUSTOMER_REGISTER_REQUEST';
export const CUSTOMER_REGISTER_SUCCESS = 'CUSTOMER_REGISTER_SUCCESS';
export const CUSTOMER_REGISTER_FAILURE = 'CUSTOMER_REGISTER_FAILURE';

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAILURE = 'CURRENT_USER_FAILURE';

export const TYPE_COMPTE_REQUEST = 'TYPE_COMPTE_REQUEST';
export const TYPE_COMPTE_SUCCESS = 'TYPE_COMPTE_SUCCESS';
export const TYPE_COMPTE_FAILURE = 'TYPE_COMPTE_FAILURE';

export const COUNTRY_REQUEST = 'COUNTRY_REQUEST';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const COUNTRY_FAILURE = 'COUNTRY_FAILURE';