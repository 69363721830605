
import React from 'react';

const NoPage = () => {
  return (
    <div>
      <p style={{fontSize: 26}}>404 Page Not Found</p>
      <p>Cette page n'existe pas</p>
    </div>
  );
};

export default NoPage;
