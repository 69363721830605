
import React, { useEffect } from "react";
import { useAuth } from "../contexts/auth-context";
import classes from "../styles/home.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import heroImg from "../images/payxof-assets/hero-img.png";
import acceptedBookmaker from "../images/payxof-assets/accepted-bookmaker.png";
import { DepositMoney, WithdrawIcon } from "../Components/Icons";
import { PartnerComponent } from "../Components/PartnerComponent";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import Slider from "../Layouts/Slider";


const partnersLogos = [
  {
    src: require("../images/payxof-assets/moov_money_img.png"),
  },
  {
    src: require("../images/payxof-assets/mtn_momo.png"),
  },

];

const Home = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [deviceScreenSize, setDeviceSetScreenSize] = React.useState('');

  useEffect(() => {
    setDeviceSetScreenSize(window.innerWidth);
  });

  const slides = [
    {
      imageSrc: require("../images/carrousel/Payxof-visuel-site-01.png"),
    },
    {
      imageSrc: require("../images/carrousel/Payxof-visuel-site-02.png"),
    },
    {
      imageSrc: require("../images/carrousel/Payxof-visuel-site-03.png"),
    },
    {
      imageSrc: require("../images/carrousel/Payxof-visuel-site-04.png"),
    },
    {
      imageSrc: require("../images/carrousel/Payxof-visuel-site-05.png"),
    },
    {
      imageSrc: require("../images/carrousel/Payxof-visuel-site-06.png"),
    },
    {
      imageSrc: require("../images/carrousel/Payxof-visuel-site-07.png"),
    },
  ];


  const handleDp = () => {
    if (isAuthenticated) {
      navigate("/deposit");
    } else {
      navigate("/signin");
    }
  };

  const HandleRt = () => {
    if (isAuthenticated) {
      navigate("/withdraw");
    } else {
      navigate("/signin");
    }
  };



  return (
    <>

      <main>
        <br />
        <Slider slides={slides} />
        <div className={classes["accepted-bookmaker-block"]}>
          <div className={classes["accepted-bookmaker-block--first_side"]}>
            <h2>Nous acceptons</h2>
            <br />
            <div className={classes["partner-main-block__partners"]}>
              {partnersLogos.map((partner, index) => {
                return <PartnerComponent partner_img={partner.src} key={index} />
              })}
            </div>
          </div>
          <div className={classes["accepted-bookmaker-block__partners"]}>
            <img src={acceptedBookmaker} alt="Bookmaker accepté à payxof" />
          </div>
        </div>

        <div className={classes["hero-block"]}>
          <div className={classes["hero-block__hero-title"]}>
            <p className={classes["hero-block__hero-title_title-one"]}>Recharge Flash !</p>
            <h1 className={classes["hero-block__hero-title_title-second"]}>Recharge ton compte 1XBET et 1WIN et retire tes gains en un rien de temps !</h1>
            <p className={classes["hero-block__hero-title_title-third"]}>Recharge, Gagne, Retire Instantanément !</p>

            <Link to="/signup" className>
              {!isAuthenticated ? <a className={classes["hero-block__hero-title_call-action-btn"]}>Nous Rejoindre</a> : ''}
            </Link>
          </div>
          <div className={classes["hero-block__hero-section"]}>
            <div>
              <svg width={deviceScreenSize <= 755 ? "200" : "400"} height="400" viewBox="-120 -60 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-anime">
                <path fill="#1abcfe">
                  <animate attributeName="d" dur={"5000ms"} repeatCount={"indefinite"}
                    values="
            M38.8,-39.7C50.7,-36.2,61.2,-24.4,58.5,-14.1C55.8,-3.8,40,5.1,31.5,16.6C22.9,28.1,21.7,42.2,13.7,51C5.6,59.8,-9.3,63.2,-18.5,57.1C-27.6,51,-30.9,35.4,-31.3,23.5C-31.8,11.5,-29.4,3.4,-31.9,-9.6C-34.4,-22.6,-41.6,-40.4,-37.1,-45.2C-32.6,-50,-16.3,-41.8,-1.4,-40C13.4,-38.3,26.9,-43.2,38.8,-39.7Z;

            M53.6,-56.4C68.4,-51.4,78.7,-33.6,78.2,-16.7C77.8,0.2,66.6,16.1,55.6,29.1C44.5,42.1,33.6,52.3,19.8,59.5C6,66.7,-10.7,71,-18.6,63C-26.5,54.9,-25.5,34.6,-25.7,21.2C-25.9,7.8,-27.2,1.4,-26,-4.4C-24.8,-10.2,-21.1,-15.4,-16.3,-22.2C-11.5,-28.9,-5.8,-37.2,6.8,-45.3C19.4,-53.4,38.8,-61.3,53.6,-56.4Z;

           M30.1,-34.9C39.3,-28.1,47.3,-18.9,46.8,-10C46.3,-1.1,37.2,7.6,32.3,21.1C27.4,34.6,26.7,52.9,20.5,54.2C14.4,55.5,2.8,39.7,-9.8,32.7C-22.5,25.6,-36.2,27.2,-50.4,20.5C-64.5,13.7,-79,-1.4,-73.7,-9.7C-68.3,-18.1,-43.1,-19.7,-27.5,-25.4C-12,-31.1,-6,-40.8,2.2,-43.5C10.4,-46.1,20.9,-41.7,30.1,-34.9Z;

           M46.3,-50.6C59.1,-44.4,68,-29,63.3,-16.9C58.7,-4.8,40.5,4,32.5,19.6C24.5,35.1,26.7,57.3,20.2,63C13.6,68.7,-1.7,57.7,-11.9,47.5C-22.1,37.4,-27.2,28,-29.7,19.3C-32.2,10.5,-32.2,2.4,-32.7,-7.7C-33.3,-17.8,-34.3,-30,-28.9,-37.5C-23.6,-45,-11.8,-47.8,2.5,-50.8C16.7,-53.7,33.5,-56.8,46.3,-50.6Z;

           M40.8,-48.8C46.6,-43.9,40.4,-25,44,-7.4C47.6,10.2,60.9,26.5,57.7,34.4C54.4,42.3,34.7,41.7,16.7,50.2C-1.4,58.6,-17.8,76.2,-26.2,72.8C-34.7,69.4,-35.3,45.1,-42.8,27.1C-50.2,9.2,-64.5,-2.3,-66.1,-14.9C-67.7,-27.4,-56.6,-40.9,-43.5,-44.5C-30.4,-48.2,-15.2,-42,1.2,-43.4C17.6,-44.8,35.1,-53.8,40.8,-48.8Z;

           M38.8,-39.7C50.7,-36.2,61.2,-24.4,58.5,-14.1C55.8,-3.8,40,5.1,31.5,16.6C22.9,28.1,21.7,42.2,13.7,51C5.6,59.8,-9.3,63.2,-18.5,57.1C-27.6,51,-30.9,35.4,-31.3,23.5C-31.8,11.5,-29.4,3.4,-31.9,-9.6C-34.4,-22.6,-41.6,-40.4,-37.1,-45.2C-32.6,-50,-16.3,-41.8,-1.4,-40C13.4,-38.3,26.9,-43.2,38.8,-39.7Z
           "></animate>

                </path>
              </svg>
            </div>
            <div className={classes["hero-block__hero-section-bg-img"]}>
            </div>
          </div>
        </div>

        <div className={classes["call-in-action-main-block"]} >
          <h2>Boost tes profits avec PayXof</h2>
          <p>Recharge ton compte, joue avec passion, et gagne encore plus en invitant tes amis à rejoindre l'aventure. Avec notre programme de parrainage, chaque nouvel inscrit grâce à toi devient une chance supplémentaire de multiplier tes gains</p>
          <a href="#recharge" className={`${classes["hero-block__hero-title_call-action-btn"]} ${classes["call-in-action-main-block__btn"]}`}>Commencer</a>
        </div>

        <div id="recharge" className={classes["carousel-feat-operation-block"]}>
          <div className={classes["start-operation"]}>
            <h2>Faites une opération maintenant</h2>
            <div className={classes["operation-btn-block"]}>
              <div className="btn-toolbar deposit-btn">
                <button
                  type="button"
                  className={` btn-lg ${classes["operation-btn-block__btn"]}`}
                  onClick={handleDp}
                >
                  <DepositMoney width={25} height={25} color={"#FFFFFF"} />&nbsp;&nbsp;
                  Dépôt
                </button>
              </div>
              <div className="btn-toolbar">
                <button
                  type="button"
                  className={` btn-lg ${classes["operation-btn-block__btn"]}`}
                  onClick={HandleRt}
                >
                  <WithdrawIcon width={25} height={25} color={"#FFFFFF"} />&nbsp;&nbsp;
                  Retrait
                </button>
              </div>
            </div>
          </div>
        </div>

        <CookieConsent
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          buttonText="Je comprends"
        >
          Ce site utilise des cookies pour vous garantir la meilleure expérience
        </CookieConsent>
      </main>
    </>
  );
};

export default Home;
